import React, { useEffect } from 'react';
import Layout from 'components/shared/Layout';
import Header from 'components/shared/header/Header';
import PageContent from 'components/shared/PageContent';
import AppleStoreImage from 'assets/images/btn_download_apple_store.svg';
import AppPreviewImage from 'assets/images/_rituals_app_download_preview_mockup_01@2x.png';
import GooglePlayImage from 'assets/images/btn_download_google_play_store.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import MemberCard from './MemberCard';
import AppleWallet from './AppleWallet';

export default function Member(): JSX.Element {
  const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Header />
      <PageContent className="member">
        <div className="info">
          <FormattedMessage id="member.heading" />
        </div>
        <MemberCard />
        <div className="incentive">
          <FormattedMessage id="member.incentive" /></div>
        <AppleWallet />
      </PageContent>
      <div className="get-more">
        <div className="heading">
          <FormattedMessage id="member.get-more.heading" />
        </div>
        <div className="download">
          <FormattedMessage id="member.get-more.download" />
        </div>
        <a href="https://apps.apple.com/app/rituals-home-body-cosmetics/id1424241203">
          <div className="appstore">
            <img
              src={AppleStoreImage}
              alt={intl.formatMessage({ id: 'apple.app-store' })}
            />
          </div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.rituals.app.prod">
          <div className="play-store">
            <img
              src={GooglePlayImage}
              alt={intl.formatMessage({ id: 'google.play-store' })}
            />
          </div>
        </a>
        <div className="preview">
          <img
            src={AppPreviewImage}
            alt={intl.formatMessage({ id: 'apple.ios' })}
          />
        </div>
      </div>
    </Layout>
  );
}
