import React from 'react';
import beamMp4 from 'assets/clips/_rituals_qr_onboarding_header_beam_extra_small.mp4';
import beamPng from 'assets/clips/_rituals_qr_onboarding_header_beam_extra_small.png';

export const HeaderBeam: React.FC = () => {
  return (
    <div className="header-beam-container">
      <div className="gradient" />
      <div className="beam">
        <video
          loop
          muted
          autoPlay
          playsInline
          className="video"
          poster={beamPng}>
          <source src={beamMp4} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default HeaderBeam;
