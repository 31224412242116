import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export default function PageHeading(props: Props): JSX.Element {
  return (
    <div className="page-heading">
      <div className="title">{props.children}</div>
      <div className="line" />
    </div>
  );
}
