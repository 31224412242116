import { ErrorsModel } from 'services/errorHandling/ErrorModel';
import { AddError } from 'services/errorHandling/ErrorUtil';
import { nameOf } from 'services/ObjectUtil';
import { RegisterModel } from './RegisterModel';

const validateResponse = (response: EVA.Core.CreateCustomerResponse) => {
  const errors: ErrorsModel = {};

  if (response.Result === 1) {
    AddError(errors, nameOf<RegisterModel>('email'), 'form.email.invalid');
  }
  if (response.Result === 3) {
    AddError(errors, nameOf<RegisterModel>('email'), 'form.email.inUse');
  }

  return errors;
};

export default validateResponse;
