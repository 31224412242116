import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'services/appContext/AppContext';

const currencySymbols: any = {
  default: '€',
  EUR: '€',
  USD: '$',
  GBP: '£',
  CHF: 'Fr.'
};

export default function HeaderCarousel(): JSX.Element {
  const { appContext } = useContext(AppContext);

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={20}
      totalSlides={4}
      isIntrinsicHeight
      isPlaying>
      <Slider>
        {[0, 1, 2, 3].map(x => (
          <Slide index={0} key={x}>
            <div className="welcome-gift">
              <div className="heading">
                <FormattedMessage
                  id={`landing-page.carousel.item${x + 1}.heading`}
                />
              </div>
              <div className="description">
                <FormattedMessage
                  id={`landing-page.carousel.item${x + 1}.description`}
                  values={{
                    currency:
                      currencySymbols[appContext.currencyID] ??
                      currencySymbols.default
                  }}
                />
              </div>
            </div>
          </Slide>
        ))}
      </Slider>
      <DotGroup />
    </CarouselProvider>
  );
}
