export type UpgradeModel = {
  gender?: string;
  dateOfBirth: string;
  phoneNumber: string;
  emailOffers: boolean;
  smsOffers: boolean;
};

export const DefaultUpgradeModel: UpgradeModel = {
  dateOfBirth: '',
  phoneNumber: '',
  emailOffers: false,
  smsOffers: false
};
