import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'services/appContext/AppContext';
import { FormattedMessage } from 'react-intl';
import useRitualsService from 'services/eva/RitualsService';
import { useHistory } from 'react-router-dom';
import useUserService from 'services/eva/UserService';
import { DefaultMemberCardModel } from './MemberCardModel';
import Barcode from './Barcode';

export default function MemberCard(): JSX.Element {
  const { appContext, setAppContext } = useContext(AppContext);
  const history = useHistory();
  const [model, setModel] = useState(DefaultMemberCardModel);
  const ritualsService = useRitualsService();
  const userService = useUserService();
  const loyalityName = "RGC_LoyaltyID";

  useEffect(() => {
    (async () => {
      try {
        const userInfo = await userService.getUserInfo();
        let isPending = false;
        const loyality = userInfo?.CustomFields.find(fields => fields.Name === loyalityName);

        if (userInfo?.User.ID) {
          const subscriptionInfo = await userService.getSubscriptions(
            userInfo?.User.ID
          );

          if (
            subscriptionInfo?.Subscriptions.find(
              sub =>
                sub.Status === 1 /* EVA.Core.SubscriptionStatus.Requested  */ &&
                sub.Subscription.BackendID === 'loyalty-newsletter'
            )
          ) {
            isPending = true;
          }
        }

        if (typeof loyality === "undefined") {
          history.push('/upgrade');
        }

        if (userInfo  && loyality) {
          if (model.loyaltyId !== loyality.Value) {
            setModel(prev => {
              return {
                ...prev,
                firstName: userInfo.User.FirstName,
                lastName: userInfo.User.LastName,
                loyaltyId: loyality.Value,
                isPending
              };
            });
          }
        }
      } catch (err) {
        setAppContext({
          ...appContext,
          userToken: undefined
        });
        history.push('/');
      }
    })();
  }, [history, ritualsService, userService, model, setAppContext, appContext]);

  return (
    <div className="member-card-container">
      {model.isPending &&
        (appContext.country === 'de' || appContext.country === 'at') && (
          <div className="pending">
            <FormattedMessage id="member-card.pending" />
          </div>
        )}
      <div className="member-card">
        <div className="image">
          <div className="info">
            <h3 className="brand">
              <FormattedMessage id="my-rituals" />
            </h3>
            <h2 className="name">
              {model.firstName} {model.lastName}
            </h2>
            <span className="code">{model.loyaltyId}</span>
          </div>
        </div>
        <div className="barcode">
          {model.loyaltyId && <Barcode value={model.loyaltyId} />}
        </div>
      </div>
    </div>
  );
}
