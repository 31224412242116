/* eslint-disable lines-between-class-members */
/* eslint-disable spaced-comment */
/// <reference path="./EVA.Rituals.d.ts" />

/* eslint-disable max-classes-per-file */
import { IEvaServiceDefinition } from '@springtree/eva-services-core';

/**
 * TODO: Needs documentation
 *
 * @export
 * @class ArvatoCreatePurchaseOrderReceipt
 * @implements {EvaService}
 */
export class ArvatoCreatePurchaseOrderReceipt implements IEvaServiceDefinition {
  name = 'Rituals:ArvatoCreatePurchaseOrderReceipt';
  path = '/api/rituals/ArvatoCreatePurchaseOrderReceipt';
  request?: EVA.Rituals.ArvatoCreatePurchaseOrderReceipt;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class ArvatoCreateShipmentStatus
 * @implements {EvaService}
 */
export class ArvatoCreateShipmentStatus implements IEvaServiceDefinition {
  name = 'Rituals:ArvatoCreateShipmentStatus';
  path = '/api/rituals/ArvatoCreateShipmentStatus';
  request?: EVA.Rituals.ArvatoCreateShipmentStatus;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class ArvatoCreateStockMutation
 * @implements {EvaService}
 */
export class ArvatoCreateStockMutation implements IEvaServiceDefinition {
  name = 'Rituals:ArvatoCreateStockMutation';
  path = '/api/rituals/ArvatoCreateStockMutation';
  request?: EVA.Rituals.ArvatoCreateStockMutation;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class ArvatoShipOrder
 * @implements {EvaService}
 */
export class ArvatoShipOrder implements IEvaServiceDefinition {
  name = 'Rituals:ArvatoShipOrder';
  path = '/api/rituals/ArvatoShipOrder';
  request?: EVA.Rituals.ArvatoShipOrder;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class ArvatoShipmentReceiptConfirmation
 * @implements {EvaService}
 */
export class ArvatoShipmentReceiptConfirmation implements IEvaServiceDefinition {
  name = 'Rituals:ArvatoShipmentReceiptConfirmation';
  path = '/api/rituals/ArvatoShipmentReceiptConfirmation';
  request?: EVA.Rituals.ArvatoShipmentReceiptConfirmation;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Invoked by Arvato to notify EVA about shipment updates of the order.
 *
 * @export
 * @class ArvatoUpdateOrder
 * @implements {EvaService}
 */
export class ArvatoUpdateOrder implements IEvaServiceDefinition {
  name = 'Rituals:ArvatoUpdateOrder';
  path = '/api/rituals/ArvatoUpdateOrder';
  request?: EVA.Rituals.ArvatoUpdateOrder;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class ArvatoUpdateStock
 * @implements {EvaService}
 */
export class ArvatoUpdateStock implements IEvaServiceDefinition {
  name = 'Rituals:ArvatoUpdateStock';
  path = '/api/rituals/ArvatoUpdateStock';
  request?: EVA.Rituals.ArvatoUpdateStock;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class CreateDemandwareOrders
 * @implements {EvaService}
 */
export class CreateDemandwareOrders implements IEvaServiceDefinition {
  name = 'Rituals:CreateDemandwareOrders';
  path = '/api/rituals/CreateDemandwareOrders';
  request?: EVA.Rituals.CreateDemandwareOrders;
  response?: EVA.Rituals.CreateDemandwareOrdersResponse;
}

/**
 * Creates a new meaningful moment.
 *
 * @export
 * @class CreateMeaningfulMoment
 * @implements {EvaService}
 */
export class CreateMeaningfulMoment implements IEvaServiceDefinition {
  name = 'Rituals:CreateMeaningfulMoment';
  path = '/api/rituals/CreateMeaningfulMoment';
  request?: EVA.Rituals.CreateMeaningfulMoment;
  response?: EVA.Rituals.CreateMeaningfulMomentResponse;
}

/**
 * Creates a new order based on a meaningful moment.
 *
 * @export
 * @class CreateMeaningfulMomentOrder
 * @implements {EvaService}
 */
export class CreateMeaningfulMomentOrder implements IEvaServiceDefinition {
  name = 'Rituals:CreateMeaningfulMomentOrder';
  path = '/api/rituals/CreateMeaningfulMomentOrder';
  request?: EVA.Rituals.CreateMeaningfulMomentOrder;
  response?: EVA.Core.SimpleShoppingCartResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class DeleteMeaningfulMoment
 * @implements {EvaService}
 */
export class DeleteMeaningfulMoment implements IEvaServiceDefinition {
  name = 'Rituals:DeleteMeaningfulMoment';
  path = '/api/rituals/DeleteMeaningfulMoment';
  request?: EVA.Rituals.DeleteMeaningfulMoment;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Temp service to flag customers as 'employees'
 *
 * @export
 * @class FriendsFamilyToggleEmployee
 * @implements {EvaService}
 */
export class FriendsFamilyToggleEmployee implements IEvaServiceDefinition {
  name = 'Rituals:FriendsFamilyToggleEmployee';
  path = '/api/rituals/FriendsFamilyToggleEmployee';
  request?: EVA.Rituals.FriendsFamilyToggleEmployee;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class GetMeaningfulMoment
 * @implements {EvaService}
 */
export class GetMeaningfulMoment implements IEvaServiceDefinition {
  name = 'Rituals:GetMeaningfulMoment';
  path = '/api/rituals/GetMeaningfulMoment';
  request?: EVA.Rituals.GetMeaningfulMoment;
  response?: EVA.Rituals.GetMeaningfulMomentResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class GetMeaningfulMomentDetails
 * @implements {EvaService}
 */
export class GetMeaningfulMomentDetails implements IEvaServiceDefinition {
  name = 'Rituals:GetMeaningfulMomentDetails';
  path = '/api/rituals/GetMeaningfulMomentDetails';
  request?: EVA.Rituals.GetMeaningfulMomentDetails;
  response?: EVA.Rituals.GetMeaningfulMomentDetailsResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class GetMeaningfulMomentGiftCard
 * @implements {EvaService}
 */
export class GetMeaningfulMomentGiftCard implements IEvaServiceDefinition {
  name = 'Rituals:GetMeaningfulMomentGiftCard';
  path = '/api/rituals/GetMeaningfulMomentGiftCard';
  request?: EVA.Rituals.GetMeaningfulMomentGiftCard;
  response?: EVA.Rituals.MeaningfulMomentGiftCardResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class GetMeaningfulMomentGreetingCard
 * @implements {EvaService}
 */
export class GetMeaningfulMomentGreetingCard implements IEvaServiceDefinition {
  name = 'Rituals:GetMeaningfulMomentGreetingCard';
  path = '/api/rituals/GetMeaningfulMomentGreetingCard';
  request?: EVA.Rituals.GetMeaningfulMomentGreetingCard;
  response?: EVA.Rituals.MeaningfulMomentGreetingCardResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class GetMeaningfulMomentMedia
 * @implements {EvaService}
 */
export class GetMeaningfulMomentMedia implements IEvaServiceDefinition {
  name = 'Rituals:GetMeaningfulMomentMedia';
  path = '/api/rituals/GetMeaningfulMomentMedia';
  request?: EVA.Rituals.GetMeaningfulMomentMedia;
  response?: EVA.Rituals.MeaningfulMomentMediaResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class GetMeaningfulMomentProductGiftCard
 * @implements {EvaService}
 */
export class GetMeaningfulMomentProductGiftCard implements IEvaServiceDefinition {
  name = 'Rituals:GetMeaningfulMomentProductGiftCard';
  path = '/api/rituals/GetMeaningfulMomentProductGiftCard';
  request?: EVA.Rituals.GetMeaningfulMomentProductGiftCard;
  response?: EVA.Rituals.MeaningfulMomentGiftCardResponse;
}

/**
 * Returns all of the surprises that have been set on the meaningful moment order.
 *
 * @export
 * @class GetMeaningfulMomentSurprises
 * @implements {EvaService}
 */
export class GetMeaningfulMomentSurprises implements IEvaServiceDefinition {
  name = 'Rituals:GetMeaningfulMomentSurprises';
  path = '/api/rituals/GetMeaningfulMomentSurprises';
  request?: EVA.Rituals.GetMeaningfulMomentSurprises;
  response?: EVA.Rituals.GetMeaningfulMomentSurprisesResponse;
}

/**
 * Backfills data for Rituals Google Cloud, by collecting data and sending it to you by mail.

The following types of data are supported:

* `SalesOrders` = 0
* `PurchaseOrders` = 1
* `Interbranch` = 2
* `CycleCounts` = 3

The service accepts these properties:

* `Type`: The type of data you want to collect (see above)
* `From` and `To`: The (inclusive) lower- and upper bound of data's `CreationTime` to backfill
* `Limit`: The maximum amount of orders overall to backfill (optional, when left empty, all data is collected)
* `Batch`: The maximum amount of orders to include in a single download file (optional, when left empty, all data will come in a single download)
* `Expire`: The amount of hours the download links are valid for (optional, defaults to 24 hours)
* `Preview`: Default false, but when set to true, no messages will be queued and no emails will be sent

As confirmation, this service will return the amount of data objects it will process, and the email address they will be sent to once complete.

:bath: This process may take a while!
  *
  * @export
  * @class GoogleBackfill
  * @implements {EvaService}
  */
export class GoogleBackfill implements IEvaServiceDefinition {
  name = 'Rituals:GoogleBackfill';
  path = '/api/rituals/GoogleBackfill';
  request?: EVA.Rituals.GoogleBackfill;
  response?: EVA.Rituals.GoogleBackfillResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class HerokuConfirmOrder
 * @implements {EvaService}
 */
export class HerokuConfirmOrder implements IEvaServiceDefinition {
  name = 'Rituals:HerokuConfirmOrder';
  path = '/api/rituals/HerokuConfirmOrder';
  request?: EVA.Rituals.HerokuConfirmOrder;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * This service can be used to request a  password reset in Janrain
 *
 * @export
 * @class JanrainRequestPasswordResetToken
 * @implements {EvaService}
 */
export class JanrainRequestPasswordResetToken implements IEvaServiceDefinition {
  name = 'Rituals:JanrainRequestPasswordResetToken';
  path = '/api/rituals/JanrainRequestPasswordResetToken';
  request?: EVA.Rituals.JanrainRequestPasswordResetToken;
  response?: EVA.Rituals.JanrainRequestPasswordResetTokenResponse;
}

/**
 * This service can be used to handle password reset tokens from Janrain
 *
 * @export
 * @class JanrainResetPassword
 * @implements {EvaService}
 */
export class JanrainResetPassword implements IEvaServiceDefinition {
  name = 'Rituals:JanrainResetPassword';
  path = '/api/rituals/JanrainResetPassword';
  request?: EVA.Rituals.JanrainResetPassword;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * List all of the meaningful moment orders that have been created by the currently logged in user.
 *
 * @export
 * @class ListMeaningfulMomentOrders
 * @implements {EvaService}
 */
export class ListMeaningfulMomentOrders implements IEvaServiceDefinition {
  name = 'Rituals:ListMeaningfulMomentOrders';
  path = '/api/rituals/ListMeaningfulMomentOrders';
  request?: EVA.Rituals.ListMeaningfulMomentOrders;
  response?: EVA.Rituals.ListMeaningfulMomentOrdersResponse;
}

/**
 * List all of the meaningful moment that have been created by the currently logged in user.
 *
 * @export
 * @class ListMeaningfulMoments
 * @implements {EvaService}
 */
export class ListMeaningfulMoments implements IEvaServiceDefinition {
  name = 'Rituals:ListMeaningfulMoments';
  path = '/api/rituals/ListMeaningfulMoments';
  request?: EVA.Rituals.ListMeaningfulMoments;
  response?: EVA.Rituals.ListMeaningfulMomentsResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class MeaningfulMomentGiftCardRequest
 * @implements {EvaService}
 */
export class MeaningfulMomentGiftCardRequest implements IEvaServiceDefinition {
  name = 'Rituals:MeaningfulMomentGiftCardRequest';
  path = '/api/rituals/MeaningfulMomentGiftCardRequest';
  request?: EVA.Rituals.MeaningfulMomentGiftCardRequest;
  response?: EVA.Rituals.MeaningfulMomentGiftCardResponse;
}

/**
 * A specific implementation for `AddProductToOrder` for GiftCards. Adds a new order line for a digital GiftCard to specified order, where
`SessionID` identifies the current session or order for the customer and `Amount` is the desired amount to create a GiftCard for.

Optionally supply a `UpdateUrl` pointing to the website you want the buyer of the GiftCard to land to see/manage the order.

Additionally, the `Data` property need to be supplied;

* `From` and `To` are the names of the sender and recipient of this gift, respectively
* `Text` is an additional text to be included on the GiftCard and presented to the recipient (optional)
* `Theme` is an object describing the theme, style or 'ritual' desired for this GiftCard;
* `ID` is a string identifying this theme
* `Name` is it's name, for display
* `Description` and `LongDescription` to describe the theme
* `BackgroundColor` and `ForegroundColor` allow setting colors to the giftcard
* `ImageUrl`, `ImageWidth` and `ImageHeight` describe the image used
  *
  * @export
  * @class RitualsAddGiftCardToOrder
  * @implements {EvaService}
  */
export class RitualsAddGiftCardToOrder implements IEvaServiceDefinition {
  name = 'Rituals:RitualsAddGiftCardToOrder';
  path = '/api/rituals/RitualsAddGiftCardToOrder';
  request?: EVA.Rituals.RitualsAddGiftCardToOrder;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Apply the EmployeeDiscount to an Order.

This service is called after a QrCode is scanned, or after
  *
  * @export
  * @class RitualsApplyEmployeeDiscount
  * @implements {EvaService}
  */
export class RitualsApplyEmployeeDiscount implements IEvaServiceDefinition {
  name = 'Rituals:RitualsApplyEmployeeDiscount';
  path = '/api/rituals/RitualsApplyEmployeeDiscount';
  request?: EVA.Rituals.RitualsApplyEmployeeDiscount;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Attaches a customer to an order identified by a `Token`, as generated by the `RitualsSendAddressFormLinkFromCart`/`RitualsSendPaazlLinkFromCart` service.
 *
 * @export
 * @class RitualsAttachCustomerToOrder
 * @implements {EvaService}
 */
export class RitualsAttachCustomerToOrder implements IEvaServiceDefinition {
  name = 'Rituals:RitualsAttachCustomerToOrder';
  path = '/api/rituals/RitualsAttachCustomerToOrder';
  request?: EVA.Rituals.RitualsAttachCustomerToOrder;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Completes the ShipFromOrder task, indicating that the shipment of the order has taken place.

The order will be shipped and the shop stock will be moved to Transit.
  *
  * @export
  * @class RitualsCompleteShipFromStoreTask
  * @implements {EvaService}
  */
export class RitualsCompleteShipFromStoreTask implements IEvaServiceDefinition {
  name = 'Rituals:RitualsCompleteShipFromStoreTask';
  path = '/api/rituals/RitualsCompleteShipFromStoreTask';
  request?: EVA.Rituals.RitualsCompleteShipFromStoreTask;
  response?: EVA.Rituals.RitualsCompleteShipFromStoreTaskResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class RitualsCreateCustomerReturn
 * @implements {EvaService}
 */
export class RitualsCreateCustomerReturn implements IEvaServiceDefinition {
  name = 'Rituals:RitualsCreateCustomerReturn';
  path = '/api/rituals/RitualsCreateCustomerReturn';
  request?: EVA.Rituals.RitualsCreateCustomerReturn;
  response?: EVA.Rituals.RitualsCreateCustomerReturnResponse;
}

/**
 * Download the GiftCard data for a specific order, based on given `OrderID` and `GiftCardID`. This order needs to be visible for the current user. Requires
the order to be shipped (automatically when it is fully paid).

Additionally, this service can be called with an `AccessToken` and `GiftCardID` combination. In this case, no user validation is done - you know both
keys to the GiftCard, so you are allowed to get it's information.

If you want, you can specify a `LanguageID` to use for rendering the report - when omitted, the `LanguageID` of the current user.

See `RitualsGetGiftCardForOrder` service for more details.
  *
  * @export
  * @class RitualsDownloadGiftCard
  * @implements {EvaService}
  */
export class RitualsDownloadGiftCard implements IEvaServiceDefinition {
  name = 'Rituals:RitualsDownloadGiftCard';
  path = '/api/rituals/RitualsDownloadGiftCard';
  request?: EVA.Rituals.RitualsDownloadGiftCard;
  response?: EVA.Core.ResourceResponseMessage;
}

/**
 * Create invites for FriendsAndFamily

For each invite it is required to supply an EmailAddress. The Name is optional.
If an User had already been invited, an error will be returned.
If there are not enough invites available, an error will be returned.
If an invalid EmailAddress is specified, an error will be returned.
  *
  * @export
  * @class RitualsFriendsFamilyCreateInvites
  * @implements {EvaService}
  */
export class RitualsFriendsFamilyCreateInvites implements IEvaServiceDefinition {
  name = 'Rituals:RitualsFriendsFamilyCreateInvites';
  path = '/api/rituals/RitualsFriendsFamilyCreateInvites';
  request?: EVA.Rituals.RitualsFriendsFamilyCreateInvites;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Fetch all invites created by the current user

The EmailAddress, Name and Status will be returned for each invite.
  *
  * @export
  * @class RitualsFriendsFamilyGetInvites
  * @implements {EvaService}
  */
export class RitualsFriendsFamilyGetInvites implements IEvaServiceDefinition {
  name = 'Rituals:RitualsFriendsFamilyGetInvites';
  path = '/api/rituals/RitualsFriendsFamilyGetInvites';
  request?: EVA.Rituals.RitualsFriendsFamilyGetInvites;
  response?: EVA.Rituals.RitualsFriendsFamilyGetInvitesResponse;
}

/**
 * Check if the given EmailAddress can be invited

Will return false for EmailAddresses that are already invited and for invalid emailaddresses
  *
  * @export
  * @class RitualsFriendsFamilyIsEmailAddressAvailable
  * @implements {EvaService}
  */
export class RitualsFriendsFamilyIsEmailAddressAvailable implements IEvaServiceDefinition {
  name = 'Rituals:RitualsFriendsFamilyIsEmailAddressAvailable';
  path = '/api/rituals/RitualsFriendsFamilyIsEmailAddressAvailable';
  request?: EVA.Rituals.RitualsFriendsFamilyIsEmailAddressAvailable;
  response?: EVA.Rituals.RitualsFriendsFamilyIsEmailAddressAvailableResponse;
}

/**
 * Generates a Apple Passbook compatible .pkpass file which is stored in Blob storage
 *
 * @export
 * @class RitualsGeneratePassbookPass
 * @implements {EvaService}
 */
export class RitualsGeneratePassbookPass implements IEvaServiceDefinition {
  name = 'Rituals:RitualsGeneratePassbookPass';
  path = '/api/rituals/RitualsGeneratePassbookPass';
  request?: EVA.Rituals.RitualsGeneratePassbookPass;
  response?: EVA.Core.ResourceResponseMessage;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class RitualsGetBlobsForReturn
 * @implements {EvaService}
 */
export class RitualsGetBlobsForReturn implements IEvaServiceDefinition {
  name = 'Rituals:RitualsGetBlobsForReturn';
  path = '/api/rituals/RitualsGetBlobsForReturn';
  request?: EVA.Rituals.RitualsGetBlobsForReturn;
  response?: EVA.Rituals.RitualsGetBlobsForReturnResponse;
}

/**
 * Retrieves SOS customer data from specified order, as indicated with either a `SessionID` or `OrderID`, set through `RitualsSetCartData`.
 *
 * @export
 * @class RitualsGetCartData
 * @implements {EvaService}
 */
export class RitualsGetCartData implements IEvaServiceDefinition {
  name = 'Rituals:RitualsGetCartData';
  path = '/api/rituals/RitualsGetCartData';
  request?: EVA.Rituals.RitualsGetCartData;
  response?: EVA.Rituals.RitualsGetCartDataResponse;
}

/**
 * Retrieves currently known customer data, identified by a `Token` as generated by the `RitualsSendAddressFormLinkFromCart`/`RitualsSendPaazlLinkFromCart` service.
 *
 * @export
 * @class RitualsGetCustomerData
 * @implements {EvaService}
 */
export class RitualsGetCustomerData implements IEvaServiceDefinition {
  name = 'Rituals:RitualsGetCustomerData';
  path = '/api/rituals/RitualsGetCustomerData';
  request?: EVA.Rituals.RitualsGetCustomerData;
  response?: EVA.Rituals.RitualsGetCustomerDataResponse;
}

/**
 * Rituals wrapper around the `GetDeliveryOrderData` service, to support identification and authentication through a `Token`, as generated by the `RitualsSendAddressFormLinkFromCart`/`RitualsSendPaazlLinkFromCart` service.
 *
 * @export
 * @class RitualsGetDeliveryOrderData
 * @implements {EvaService}
 */
export class RitualsGetDeliveryOrderData implements IEvaServiceDefinition {
  name = 'Rituals:RitualsGetDeliveryOrderData';
  path = '/api/rituals/RitualsGetDeliveryOrderData';
  request?: EVA.Rituals.RitualsGetDeliveryOrderData;
  response?: EVA.Core.GetDeliveryOrderDataResponse;
}

/**
 * Get the info for the EmployeeDiscount
 *
 * @export
 * @class RitualsGetEmployeeDiscount
 * @implements {EvaService}
 */
export class RitualsGetEmployeeDiscount implements IEvaServiceDefinition {
  name = 'Rituals:RitualsGetEmployeeDiscount';
  path = '/api/rituals/RitualsGetEmployeeDiscount';
  request?: EVA.Rituals.RitualsGetEmployeeDiscount;
  response?: EVA.Rituals.RitualsGetEmployeeDiscountResponse;
}

/**
 * Retrieve the GiftCard data for a specific order, based on given `OrderID`. This order needs to be visible for the current user.

Will return a list of `GiftCard` data, for every orderline with a GiftCard. Will return the same data supplied in the `RitualsAddGiftCardToOrder`
service; `Theme`, `From`, `To` and `Text`. When the order was shipped (automatically when it is fully paid), the details `GiftCardNumber` and
`Pin` are also included. An additional property `IsAvailable` let's you check if it was indeed shipped and paid.

The `GiftCardID` returned on the GiftCard is it's unique identifier, to be used in conjunction with the `AccessToken` to make it globally
unique. Using the `AccessToken` and `GiftCardID`, we are pretty sure you are allowed to see the GiftCard data. Use this data to build the link
to share directly, or use the approperiate services to mail/text/print this.

Additionally, this service can be called with an `AccessToken` and `GiftCardID` combination, as explained above. In this case, no user validation
is done - you know both keys to the GiftCard, so you are allowed to get it's information.
  *
  * @export
  * @class RitualsGetGiftCardForOrder
  * @implements {EvaService}
  */
export class RitualsGetGiftCardForOrder implements IEvaServiceDefinition {
  name = 'Rituals:RitualsGetGiftCardForOrder';
  path = '/api/rituals/RitualsGetGiftCardForOrder';
  request?: EVA.Rituals.RitualsGetGiftCardForOrder;
  response?: EVA.Rituals.RitualsGetGiftCardForOrderResponse;
}

/**
 * Gets the GiftCard mail information identified by the `OrderID` and `GiftCardID`.
 *
 * @export
 * @class RitualsGetGiftCardMailOptions
 * @implements {EvaService}
 */
export class RitualsGetGiftCardMailOptions implements IEvaServiceDefinition {
  name = 'Rituals:RitualsGetGiftCardMailOptions';
  path = '/api/rituals/RitualsGetGiftCardMailOptions';
  request?: EVA.Rituals.RitualsGetGiftCardMailOptions;
  response?: EVA.Rituals.RitualsGetGiftCardMailOptionsResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class RitualsGetOrderForReturn
 * @implements {EvaService}
 */
export class RitualsGetOrderForReturn implements IEvaServiceDefinition {
  name = 'Rituals:RitualsGetOrderForReturn';
  path = '/api/rituals/RitualsGetOrderForReturn';
  request?: EVA.Rituals.RitualsGetOrderForReturn;
  response?: EVA.Rituals.RitualsGetOrderForReturnResponse;
}

/**
 * Get the Rituals user data. When logged in as employee, specify the `UserID` parameter. As customer you'll always get your own user data.

FirendsFamilyInfo:
`InvitesAvailable`: how many invites this user can create
`IsInvited`: if this user is invited
`InvitationStatus`: when the user is invited, this property will tell you if the discount has been used already
  *
  * @export
  * @class RitualsGetUserDataForUser
  * @implements {EvaService}
  */
export class RitualsGetUserDataForUser implements IEvaServiceDefinition {
  name = 'Rituals:RitualsGetUserDataForUser';
  path = '/api/rituals/RitualsGetUserDataForUser';
  request?: EVA.Rituals.RitualsGetUserDataForUser;
  response?: EVA.Rituals.RitualsGetUserDataForUserResponse;
}

/**
 * Imports the Stations and Devices from the supplied Excel file. Requires a valid Sentinel `Token` to push data through.
 *
 * @export
 * @class RitualsImportStations
 * @implements {EvaService}
 */
export class RitualsImportStations implements IEvaServiceDefinition {
  name = 'Rituals:RitualsImportStations';
  path = '/api/rituals/RitualsImportStations';
  request?: EVA.Rituals.RitualsImportStations;
  response?: EVA.Rituals.RitualsImportStationsResponse;
}

/**
 * Generate station device barcode images on a background blob. Defaults to square size 300px and an offset of 50px.
 *
 * @export
 * @class RitualsImportStationsBarcodes
 * @implements {EvaService}
 */
export class RitualsImportStationsBarcodes implements IEvaServiceDefinition {
  name = 'Rituals:RitualsImportStationsBarcodes';
  path = '/api/rituals/RitualsImportStationsBarcodes';
  request?: EVA.Rituals.RitualsImportStationsBarcodes;
  response?: EVA.Core.ResourceResponseMessage;
}

/**
 * Downloads a sample Excel file for the `RitualsImportStations` service.
 *
 * @export
 * @class RitualsImportStationsSample
 * @implements {EvaService}
 */
export class RitualsImportStationsSample implements IEvaServiceDefinition {
  name = 'Rituals:RitualsImportStationsSample';
  path = '/api/rituals/RitualsImportStationsSample';
  request?: EVA.Rituals.RitualsImportStationsSample;
  response?: EVA.Core.ResourceResponseMessage;
}

/**
 * List the store that are available for pickup in the area.

It is required to set the Latitude and Longitude in the PageConfig filter.
Next to that it is required to supply an `OrderID`, or a list of `Products`.
  *
  * @export
  * @class RitualsListAvailablePickupStores
  * @implements {EvaService}
  */
export class RitualsListAvailablePickupStores implements IEvaServiceDefinition {
  name = 'Rituals:RitualsListAvailablePickupStores';
  path = '/api/rituals/RitualsListAvailablePickupStores';
  request?: EVA.Rituals.RitualsListAvailablePickupStores;
  response?: EVA.Rituals.RitualsListAvailablePickupStoresResponse;
}

/**
 * Rituals wrapper around the `PaazlGetShippingOptionsForOrder` service, to support identification and authentication through a `Token`, as generated by the `RitualsSendAddressFormLinkFromCart`/`RitualsSendPaazlLinkFromCart` service.
 *
 * @export
 * @class RitualsPaazlGetShippingOptionsForOrder
 * @implements {EvaService}
 */
export class RitualsPaazlGetShippingOptionsForOrder implements IEvaServiceDefinition {
  name = 'Rituals:RitualsPaazlGetShippingOptionsForOrder';
  path = '/api/rituals/RitualsPaazlGetShippingOptionsForOrder';
  request?: EVA.Rituals.RitualsPaazlGetShippingOptionsForOrder;
  response?: EVA.Carrier.Paazl.PaazlGetShippingOptionsForOrderResponse;
}

/**
 * Rituals wrapper around the `PaazlUpdateShippingOptionsForOrder` service, to support identification and authentication through a `Token`, as generated by the `RitualsSendAddressFormLinkFromCart`/`RitualsSendPaazlLinkFromCart` service.
 *
 * @export
 * @class RitualsPaazlUpdateShippingOptionsForOrder
 * @implements {EvaService}
 */
export class RitualsPaazlUpdateShippingOptionsForOrder implements IEvaServiceDefinition {
  name = 'Rituals:RitualsPaazlUpdateShippingOptionsForOrder';
  path = '/api/rituals/RitualsPaazlUpdateShippingOptionsForOrder';
  request?: EVA.Rituals.RitualsPaazlUpdateShippingOptionsForOrder;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Testservice to push an Order to Google with a specific Status
 *
 * @export
 * @class RitualsPushOrderToGoogle
 * @implements {EvaService}
 */
export class RitualsPushOrderToGoogle implements IEvaServiceDefinition {
  name = 'Rituals:RitualsPushOrderToGoogle';
  path = '/api/rituals/RitualsPushOrderToGoogle';
  request?: EVA.Rituals.RitualsPushOrderToGoogle;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Request to remove all user related data for GDPR

Provide an EVA userid, or a GoogleContactID
  *
  * @export
  * @class RitualsRequestPrivacyRemoval
  * @implements {EvaService}
  */
export class RitualsRequestPrivacyRemoval implements IEvaServiceDefinition {
  name = 'Rituals:RitualsRequestPrivacyRemoval';
  path = '/api/rituals/RitualsRequestPrivacyRemoval';
  request?: EVA.Rituals.RitualsRequestPrivacyRemoval;
  response?: EVA.Privacy.CreatePrivacyRemovalRequestResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class RitualsScanLoyaltyCard
 * @implements {EvaService}
 */
export class RitualsScanLoyaltyCard implements IEvaServiceDefinition {
  name = 'Rituals:RitualsScanLoyaltyCard';
  path = '/api/rituals/RitualsScanLoyaltyCard';
  request?: EVA.Rituals.RitualsScanLoyaltyCard;
  response?: EVA.Rituals.RitualsScanLoyaltyCardResponse;
}

/**
 * Sends a link to a contact form to attach a SOS customer.

The contact form is specified through the `Url` and will be appended with a `token` GET parameter to authorize the recipient to modify the attached
customer for the order. Specify the order using either a `SessionID` or `OrderID`, and attach a text to this link using the `Text` parameter.
  *
  * @export
  * @class RitualsSendAddressFormLinkFromCart
  * @implements {EvaService}
  */
export class RitualsSendAddressFormLinkFromCart implements IEvaServiceDefinition {
  name = 'Rituals:RitualsSendAddressFormLinkFromCart';
  path = '/api/rituals/RitualsSendAddressFormLinkFromCart';
  request?: EVA.Rituals.RitualsSendAddressFormLinkFromCart;
  response?: EVA.Rituals.SOSUrlResponse;
}

/**
 * Sends a carousel of products in the current cart, identified by either a `SessionID` or `OrderID`. Specify `OrderLineIDs` to indicate the lines to
send, or leave undefined to send all products.

Requires SOS customer data to be set to the order (through `RitualsSetCartData`).
  *
  * @export
  * @class RitualsSendCarouselFromCart
  * @implements {EvaService}
  */
export class RitualsSendCarouselFromCart implements IEvaServiceDefinition {
  name = 'Rituals:RitualsSendCarouselFromCart';
  path = '/api/rituals/RitualsSendCarouselFromCart';
  request?: EVA.Rituals.RitualsSendCarouselFromCart;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Sends the GiftCard information identified by the `OrderID` and `GiftCardID` to specified `ToEmailAddress`, with `FromEmailAddress` as sender. If you
do not specify the `FromEmailAddress` parameter, we will default to the Order.Customer.EmailAddress.

Optionally, you can schedule this email for a specific moment; use `DeliverySchedule` to plan this delivery.

:e-mail: Note: the recipient will be able to see all GiftCard data!
  *
  * @export
  * @class RitualsSendGiftCardByMail
  * @implements {EvaService}
  */
export class RitualsSendGiftCardByMail implements IEvaServiceDefinition {
  name = 'Rituals:RitualsSendGiftCardByMail';
  path = '/api/rituals/RitualsSendGiftCardByMail';
  request?: EVA.Rituals.RitualsSendGiftCardByMail;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Sends a link to a Paazl form to attach a SOS customer.

The Paazl form is specified through the `Url` and will be appended with a `token` GET parameter to authorize the recipient to modify the attached
customer for the order. Specify the order using either a `SessionID` or `OrderID`, and attach a text to this link using the `Text` parameter.
  *
  * @export
  * @class RitualsSendPaazlLinkFromCart
  * @implements {EvaService}
  */
export class RitualsSendPaazlLinkFromCart implements IEvaServiceDefinition {
  name = 'Rituals:RitualsSendPaazlLinkFromCart';
  path = '/api/rituals/RitualsSendPaazlLinkFromCart';
  request?: EVA.Rituals.RitualsSendPaazlLinkFromCart;
  response?: EVA.Rituals.SOSUrlResponse;
}

/**
 * Sends a payment link from the current cart, identified by either a `SessionID` or `OrderID`. Requires SOS customer data to be set to the order (through `RitualsSetCartData`).

* When `Url` is left empty, a valid, pending payment with Adyen HPP is required.
* With the `Url` parameter specified, that exact link will be sent.

This service will also queue a reminder message after X minutes, configured using the setting `Rituals:SOS:PaymentReminderMinutes`.
  *
  * @export
  * @class RitualsSendPaymentLinkFromCart
  * @implements {EvaService}
  */
export class RitualsSendPaymentLinkFromCart implements IEvaServiceDefinition {
  name = 'Rituals:RitualsSendPaymentLinkFromCart';
  path = '/api/rituals/RitualsSendPaymentLinkFromCart';
  request?: EVA.Rituals.RitualsSendPaymentLinkFromCart;
  response?: EVA.Rituals.SOSUrlResponse;
}

/**
 * Sends a receipt of the current cart, identified by either a `SessionID` or `OrderID`, optionally including specified `Text`.

Requires SOS customer data to be set to the order (through `RitualsSetCartData`).
  *
  * @export
  * @class RitualsSendReceiptFromCart
  * @implements {EvaService}
  */
export class RitualsSendReceiptFromCart implements IEvaServiceDefinition {
  name = 'Rituals:RitualsSendReceiptFromCart';
  path = '/api/rituals/RitualsSendReceiptFromCart';
  request?: EVA.Rituals.RitualsSendReceiptFromCart;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Attaches SOS customer data to specified order, as indicated with either a `SessionID` or `OrderID`.

The Data object supports the following properties:

* `ClientID`, to store the unique identifier for the customer on a platform
* `PlatformID`, to store the unique identifier of the platform channel
* `PlatformType`, to the which type this platform is (currently 0 = Facebook (Messenger), 1 = WhatsApp)
* `PaymentReminderSent`, a boolean flag indicating if a reminder was sent already (to prevent multiple, EVA will manage this for you)
* `Data`, a free dictionary for any other data you may wanna store
  *
  * @export
  * @class RitualsSetCartData
  * @implements {EvaService}
  */
export class RitualsSetCartData implements IEvaServiceDefinition {
  name = 'Rituals:RitualsSetCartData';
  path = '/api/rituals/RitualsSetCartData';
  request?: EVA.Rituals.RitualsSetCartData;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Starts a ShipFromStore task, identified by its UserTaskID.

The service returns the order that should be shipped from the user's current store as well as the OrderLines
that should be part of the shipment. Not necessarily all orderlines of the order should be shipped, so the
OrderLineIDs returned by this service should be respected.
  *
  * @export
  * @class RitualsStartShipFromStoreTask
  * @implements {EvaService}
  */
export class RitualsStartShipFromStoreTask implements IEvaServiceDefinition {
  name = 'Rituals:RitualsStartShipFromStoreTask';
  path = '/api/rituals/RitualsStartShipFromStoreTask';
  request?: EVA.Rituals.RitualsStartShipFromStoreTask;
  response?: EVA.Rituals.RitualsStartShipFromStoreTaskResponse;
}

/**
 * Sets the new mail values for the GiftCard identified by the `OrderID` and `GiftCardID`.

If you do not specify the `FromEmailAddress` parameter, we will default to the Order.Customer.EmailAddress.
  *
  * @export
  * @class RitualsUpdateGiftCardMailOptions
  * @implements {EvaService}
  */
export class RitualsUpdateGiftCardMailOptions implements IEvaServiceDefinition {
  name = 'Rituals:RitualsUpdateGiftCardMailOptions';
  path = '/api/rituals/RitualsUpdateGiftCardMailOptions';
  request?: EVA.Rituals.RitualsUpdateGiftCardMailOptions;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * This service allows updating giftcard lines created with the `RitualsAddGiftCardToOrder` service.

Additionally, the `Data` property need to be supplied;

* `From` and `To` are the names of the sender and recipient of this gift, respectively
* `Text` is an additional text to be included on the GiftCard and presented to the recipient (optional)
* `Theme` is an object describing the theme, style or 'ritual' desired for this GiftCard;
* `ID` is a string identifying this theme
* `Name` is it's name, for display
* `Description` and `LongDescription` to describe the theme
* `BackgroundColor` and `ForegroundColor` allow setting colors to the giftcard
* `ImageWidth` and `ImageHeight` describe the image used
  *
  * @export
  * @class RitualsUpdateGiftCardOrderLine
  * @implements {EvaService}
  */
export class RitualsUpdateGiftCardOrderLine implements IEvaServiceDefinition {
  name = 'Rituals:RitualsUpdateGiftCardOrderLine';
  path = '/api/rituals/RitualsUpdateGiftCardOrderLine';
  request?: EVA.Rituals.RitualsUpdateGiftCardOrderLine;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * Updates the Rituals loyalty setting. When logged in as employee, specify the `UserID` parameter. As customer you'll always manage your own user setting.
 *
 * @export
 * @class RitualsUpdateLoyalty
 * @implements {EvaService}
 */
export class RitualsUpdateLoyalty implements IEvaServiceDefinition {
  name = 'Rituals:RitualsUpdateLoyalty';
  path = '/api/rituals/RitualsUpdateLoyalty';
  request?: EVA.Rituals.RitualsUpdateLoyalty;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class RitualsUpdateReturnOrder
 * @implements {EvaService}
 */
export class RitualsUpdateReturnOrder implements IEvaServiceDefinition {
  name = 'Rituals:RitualsUpdateReturnOrder';
  path = '/api/rituals/RitualsUpdateReturnOrder';
  request?: EVA.Rituals.RitualsUpdateReturnOrder;
  response?: EVA.Core.EmptyResponseMessage;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class SetMeaningfulMomentGiftCard
 * @implements {EvaService}
 */
export class SetMeaningfulMomentGiftCard implements IEvaServiceDefinition {
  name = 'Rituals:SetMeaningfulMomentGiftCard';
  path = '/api/rituals/SetMeaningfulMomentGiftCard';
  request?: EVA.Rituals.SetMeaningfulMomentGiftCard;
  response?: EVA.Rituals.MeaningfulMomentGiftCardResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class SetMeaningfulMomentGreetingCard
 * @implements {EvaService}
 */
export class SetMeaningfulMomentGreetingCard implements IEvaServiceDefinition {
  name = 'Rituals:SetMeaningfulMomentGreetingCard';
  path = '/api/rituals/SetMeaningfulMomentGreetingCard';
  request?: EVA.Rituals.SetMeaningfulMomentGreetingCard;
  response?: EVA.Rituals.MeaningfulMomentGreetingCardResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class SetMeaningfulMomentMedia
 * @implements {EvaService}
 */
export class SetMeaningfulMomentMedia implements IEvaServiceDefinition {
  name = 'Rituals:SetMeaningfulMomentMedia';
  path = '/api/rituals/SetMeaningfulMomentMedia';
  request?: EVA.Rituals.SetMeaningfulMomentMedia;
  response?: EVA.Rituals.MeaningfulMomentMediaResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class SetMeaningfulMomentProductGiftCard
 * @implements {EvaService}
 */
export class SetMeaningfulMomentProductGiftCard implements IEvaServiceDefinition {
  name = 'Rituals:SetMeaningfulMomentProductGiftCard';
  path = '/api/rituals/SetMeaningfulMomentProductGiftCard';
  request?: EVA.Rituals.SetMeaningfulMomentProductGiftCard;
  response?: EVA.Rituals.MeaningfulMomentGiftCardResponse;
}

/**
 * TODO: Needs documentation
 *
 * @export
 * @class UpdateMeaningfulMoment
 * @implements {EvaService}
 */
export class UpdateMeaningfulMoment implements IEvaServiceDefinition {
  name = 'Rituals:UpdateMeaningfulMoment';
  path = '/api/rituals/UpdateMeaningfulMoment';
  request?: EVA.Rituals.UpdateMeaningfulMoment;
  response?: EVA.Core.EmptyResponseMessage;
}
