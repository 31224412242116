import { useState } from 'react';
import { ErrorsModel, ErrorModel } from './ErrorModel';

export const useErrors = (): ErrorState => {
  const [errors, setErrors] = useState<ErrorsModel>({});

  const setErrorsWrapper = (key: string) => {
    setErrors(prevState => {
      return { ...prevState, key: [] };
    });
  };

  const getErrorHandler = (key: string): ErrorHandler => {
    const clear = () => setErrorsWrapper(key);
    const getErrors = () => errors[key];

    return { clear, getErrors };
  };

  return { getErrorHandler, setErrors };
};

export type ErrorHandler = {
  clear: () => void;
  getErrors: () => ErrorModel[];
};

export type ErrorState = {
  getErrorHandler: (key: string) => ErrorHandler;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, ErrorModel[]>>>;
};
