import classNames from 'classnames';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

export default function PageContent(props: Props): JSX.Element {
  return (
    <div className={classNames('page-content', props.className)}>
      {props.children}
    </div>
  );
}
