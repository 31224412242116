import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import HeaderBeam from './HeaderBeam';

type Props = {
  children?: ReactNode;
  backButtonLink?: string;
};

export default function Header(props: Props): JSX.Element {
  return (
    <header>
      {props.backButtonLink &&
        <div className="navigation">
          <Link to={props.backButtonLink}>
            <div className="back-button" />
          </Link>
        </div>
      }
      <HeaderBeam />
      <div className="welcome">
        <FormattedMessage id="landing-page.welcome" />
      </div>
      <div className="my-rituals">
        <FormattedMessage id="my-rituals" />
      </div>
      <div className="logo">
        <div className="icon-my-rituals" />
        <div className="icon-blur-anticlockwise" />
        <div className="icon-blur-clockwise" />
      </div>
      {props.children}
    </header>
  );
}
