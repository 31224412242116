import EvaService from './EvaService';

const BarcodeService = {
  getBarcodeUrl: async (value: string) => {
    const assetsUrl = await EvaService.getAssetsUrl();

    return `${assetsUrl}/barcode/EAN/160/40/${value}`;
  }
};

export default BarcodeService;
