import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from 'components/shared/Layout';
import { useErrors } from 'services/errorHandling/useErrors';
import { NoErrors } from 'services/errorHandling/ErrorUtil';
import useUserService from 'services/eva/UserService';
import Input from 'components/shared/form/Input';
import Button from 'components/shared/form/Button';
import { nameOf } from 'services/ObjectUtil';
import { ResetPasswordModel } from 'components/resetPassword/ResetPasswordModel';
import { Link } from 'react-router-dom';
import PageContent from 'components/shared/PageContent';
import PageHeading from 'components/shared/PageHeading';
import validateModel from './ResetPasswordModelValidator';

export default function ResetPassword(): JSX.Element {
  const { getErrorHandler, setErrors } = useErrors();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const userService = useUserService();
  const { search } = window.location;
  const params = new URLSearchParams(search);

  const token = params.get('code');

  const [model, setModel] = useState({
    newPassword: '',
    newPasswordRepeat: ''
  });

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationResult = validateModel(model);
    setErrors(validationResult);

    if (NoErrors(validationResult) && token) {
      setSubmitDisabled(true);

      userService
        .resetPassword(token, model.newPassword)
        .then(() => {
          setPasswordUpdated(true);
        })
        .catch(() => {
          setSubmitDisabled(false);
          setUpdateError(true);
        });
    }
  };

  return (
    <Layout>
      <PageContent className="forgot-password">
        <div className="navigation">
          <Link to="/">
            <div className="back-button" />
          </Link>
        </div>
        <PageHeading>
          <FormattedMessage id="reset-password.heading" />
        </PageHeading>
        <div>
          <form onSubmit={submitHandler}>
            <Input
              titleId="form.password.title"
              placeholderId="form.password.placeholder"
              type="password"
              value={model.newPassword}
              errorHandler={getErrorHandler(
                nameOf<ResetPasswordModel>('newPassword')
              )}
              onChange={(e): void =>
                setModel({ ...model, newPassword: e.target.value })
              }
            />
            <Input
              titleId="form.confirm-password.title"
              placeholderId="form.confirm-password.placeholder"
              type="password"
              value={model.newPasswordRepeat}
              errorHandler={getErrorHandler(
                nameOf<ResetPasswordModel>('newPasswordRepeat')
              )}
              onChange={(e): void =>
                setModel({ ...model, newPasswordRepeat: e.target.value })
              }
            />
            <Button
              type="submit"
              textId="reset-password.form.button"
              disabled={submitDisabled}
            />
          </form>
          {passwordUpdated && (
            <div className="success">
              <FormattedMessage
                id="reset-password.form.success"
                values={{
                  link: (
                    <Link to="/">
                      <FormattedMessage id="reset-password.form.success.link" />
                    </Link>
                  )
                }}
              />
            </div>
          )}
          {updateError && (
            <span>
              <FormattedMessage
                id="reset-password.form.error"
                values={{
                  link: (
                    <Link to="/forgotPassword">
                      <FormattedMessage id="reset-password.form.error.link" />
                    </Link>
                  )
                }}
              />
            </span>
          )}
        </div>
      </PageContent>
    </Layout>
  );
}
