import classNames from 'classnames';
import DraggableModal from 'components/shared/DraggableModal';
import React, { useCallback, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'services/appContext/AppContext';
import useUserService from 'services/eva/UserService';

type Props = {
  email: string;
  closeModal: () => void;
};

export default function ForgotPasswordModal(props: Props): JSX.Element {
  const { appContext } = useContext(AppContext);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const userService = useUserService();
  const [emailSent, setEmailSent] = useState(false);

  const submitHandler = useCallback(() => {
    if (emailSent) {
      props.closeModal();
    } else {
      setSubmitDisabled(true);

      userService
        .requestPasswordResetToken(props.email, appContext.locale)
        .then(() => {
          setEmailSent(true);
          setSubmitDisabled(false);
        });
    }
  }, [appContext.locale, emailSent, props, userService]);

  return (
    <DraggableModal closeModal={props.closeModal}>
      <div className="title">
        <FormattedMessage
          id={
            emailSent
              ? 'forgot-password.title.success'
              : 'forgot-password.title'
          }
        />
      </div>
      <div className="text">
        <FormattedMessage
          id={
            emailSent ? 'forgot-password.text.success' : 'forgot-password.text'
          }
          values={{ email: <b>{props.email}</b> }}
        />
      </div>
      <button
        type="button"
        className={classNames(
          'reset-button',
          submitDisabled ? 'reset-button-disabled' : ''
        )}
        onClick={submitHandler}
        disabled={submitDisabled}>
        <FormattedMessage
          id={
            emailSent
              ? 'forgot-password.button.continue'
              : 'forgot-password.button'
          }
        />
      </button>
      {!emailSent && (
        <div className="cancel">
          <button type="button" onClick={props.closeModal}>
            <FormattedMessage id={emailSent ? 'continue' : 'cancel'} />
          </button>
        </div>
      )}
    </DraggableModal>
  );
}
