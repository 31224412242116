import { nameOf } from 'services/ObjectUtil';
import { ErrorsModel } from 'services/errorHandling/ErrorModel';
import { AddError } from 'services/errorHandling/ErrorUtil';
import { LandingPageModel } from './LandingPageModel';

const validateModel = (model: LandingPageModel) => {
  const errors: ErrorsModel = {};

  if (!model.email) {
    AddError(errors, nameOf<LandingPageModel>('email'), 'form.email.required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(model.email)) {
    AddError(
      errors,
      nameOf<LandingPageModel>('email'),
      'form.email.invalid'
    );
  }
  if (model.showPasswordField && !model.password) {
    AddError(
      errors,
      nameOf<LandingPageModel>('password'),
      'form.password.required'
    );
  }

  return errors;
};

export default validateModel;
