import React, { ReactNode } from 'react';
import Draggable from 'react-draggable';

type Props = {
  children: ReactNode;
  closeModal: () => void;
};

export default function DraggableModal(props: Props): JSX.Element {
  return (
    <Draggable
      axis="y"
      handle=".drag"
      bounds={{ top: 0 }}
      onStop={props.closeModal}>
      <div className="forgot-password-modal">
        <div className="drag">
          <div className="drag-line" />
        </div>
        {props.children}
      </div>
    </Draggable>
  );
}
