export type LandingPageModel = {
  email: string;
  password: string;
  showPasswordField: boolean;
};

export const DefaultLandingPageModel: LandingPageModel = {
  email: '',
  password: '',
  showPasswordField: false
};
