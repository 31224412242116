import { get, find } from 'lodash';
import evaConfig from 'assets/config/eva-config.json';
import { Core } from '@springtree/eva-services-core'
import EvaEndpointProvider from './EvaEndpointProvider';

export const DEFAULT_COUNTRY_ID = 'de'

export interface ICountry {
  id: string,
  availableLanguages: string [],
  token: string,
  endpoint: string;
  key: string
}

function useCountryService() {

  const getCountryById = (countryId: string): ICountry | undefined => {
    const availableCountries: ICountry[] = get(evaConfig, 'metadata.availableCountries');
    const countryInfo: ICountry | undefined = find(availableCountries, { id: countryId });

    if(!countryInfo) {
      return find(availableCountries, { id: DEFAULT_COUNTRY_ID });
    }

    return  countryInfo;
  }

 
  const getApplicationConfigurationForCountry = async (token: string) => {
    const evaEndpoint = await EvaEndpointProvider.getEndpoint();

    return evaEndpoint.callService(
      Core.GetApplicationConfiguration,
      {

      },
      {
        authenticationToken: token
      }
    );
  }

  return { getCountryById, getApplicationConfigurationForCountry }
}

export default useCountryService;
