import React, { ReactNode } from 'react';
import Footer from './Footer';

type Props = {
  children: ReactNode;
};

export default function Layout(props: Props): JSX.Element {
  return (
    <>
      <div className="page-container">{props.children}</div>
      <Footer />
    </>
  );
}
