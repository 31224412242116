import React from 'react';

export type AppContext = {
  userId: number;
  email: string;
  locale: string;
  country: string;
  language: string;
  currencyID: string;
  availableLanguages: string[];
  userRequirements?: EVA.Core.UserRequirementFor[];
  authenticationToken?: string;
  userToken?: string;
  endpoint?: string;
  endpointKey?: string;
};

export const DefaultAppContext: AppContext = {
  userId: 0,
  locale: 'de-DE',
  language: 'de',
  country: 'de',
  currencyID: 'EUR',
  email: '',
  availableLanguages: ['de', 'en']
};

type AppContextWrapper = {
  appContext: AppContext;
  setAppContext: (newAppContext: AppContext) => unknown;
};

export const AppContext = React.createContext<AppContextWrapper>({
  appContext: DefaultAppContext,
  setAppContext: () => {}
});
