import React, { useContext, ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from 'services/appContext/AppContext';

type Props = {
  children: ReactNode;
  path?: string | string[];
};

export default function PrivateRoute(props: Props) {
  const { appContext } = useContext(AppContext);
  const isAuthenticated = appContext.userToken !== undefined;

  return (
    <Route
      path={props.path}
      render={({ location }) =>
        isAuthenticated ? (
          <>{props.children}</>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        )
      }
    />
  );
}
