import React, { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Layout from 'components/shared/Layout';
import Input from 'components/shared/form/Input';
import Button from 'components/shared/form/Button';
import { NoErrors, AddError } from 'services/errorHandling/ErrorUtil';
import { nameOf } from 'services/ObjectUtil';
import { useErrors } from 'services/errorHandling/useErrors';
import { AppContext } from 'services/appContext/AppContext';
import useUserService from 'services/eva/UserService';
import { ErrorsModel } from 'services/errorHandling/ErrorModel';
import PageHeading from 'components/shared/PageHeading';
import PageContent from 'components/shared/PageContent';
import Header from 'components/shared/header/Header';
import HeaderCarousel from 'components/shared/header/HeaderCarousel';
import validateModel from './LandingPageModelValidator';
import { DefaultLandingPageModel, LandingPageModel } from './LandingPageModel';
import ForgotPasswordModal from './ForgotPasswordModal';

export default function LandingPage(): JSX.Element {
  const history = useHistory();
  const { appContext, setAppContext } = useContext(AppContext);
  const { getErrorHandler, setErrors } = useErrors();
  const [model, setModel] = useState(DefaultLandingPageModel);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [emailIsAvailable, setEmailIsAvailable] = useState(false);
  const [showForgotPasswordButton, setShowForgetPassswordButton] = useState(
    false
  );
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [submitButtonTextId, setSubmitButtonTextId] = useState(
    'landing-page.form.button.login-signup'
  );
  const userService = useUserService();
  const loyalityName = "RGC_LoyaltyID";

  const handleLoginResponse = async (
    response: EVA.Core.LoginResponse | undefined
  ) => {
    if (response && response.Authentication === 2) {
      userService
        .getUserDataWithToken(response.AuthenticationToken)
        .then(userResponse => {
          if (userResponse?.CustomFields) {
            const loyality = userResponse?.CustomFields.find(fields => fields.Name === loyalityName );

            setAppContext({
              ...appContext,
              userToken: response.AuthenticationToken,
              userId: response.User.ID
            });
  
            if (typeof loyality !== "undefined") {
              history.push('/member');
            } else {
              history.push('/upgrade');
            }
          }
    
        });
    } else {
      const errors: ErrorsModel = {};

      AddError(
        errors,
        nameOf<LandingPageModel>('password'),
        'form.password.invalid-login'
      );

      setErrors(errors);
      setSubmitDisabled(false);
    }
  };

  const handleError = (response: any) => {
    const errors: ErrorsModel = {};

    if (response.data.Error) {
      AddError(
        errors,
        nameOf<LandingPageModel>('email'),
        response.data.Error.Message,
        false
      );
    }

    setErrors(errors);
    setSubmitDisabled(false);
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (emailIsAvailable) {
      setAppContext({ ...appContext, email: model.email });
      history.push('/register');
      return;
    }

    const validationResult = validateModel(model);
    setErrors(validationResult);

    if (NoErrors(validationResult)) {
      setSubmitDisabled(true);

      if (model.showPasswordField) {
        userService
          .login(model.email, model.password)
          .then(x => handleLoginResponse(x))
          .catch(x => handleError(x));
      } else {
        userService
          .checkEmailAddressAvailability(model.email)
          .then(isAvailable => {
            if (isAvailable) {
              setAppContext({ ...appContext, email: model.email, userToken: undefined });
              history.push('/register');
            } else {
              setSubmitDisabled(false);
              setShowForgetPassswordButton(true);
              setModel({ ...model, showPasswordField: true });
              setSubmitButtonTextId('landing-page.form.button.login');
            }
          });
      }
    }
  };

  return (
    <Layout>
      <Header>
        <HeaderCarousel />
      </Header>
      <PageContent className="landing-page">
        <PageHeading>
          <FormattedMessage id="landing-page.heading" />
        </PageHeading>
        <form onSubmit={submitHandler}>
          <Input
            titleId="form.email.title"
            placeholderId="form.email.placeholder"
            value={model.email}
            type="email"
            autoComplete="username"
            errorHandler={getErrorHandler(nameOf<LandingPageModel>('email'))}
            onChange={(e): void => {
              setModel({
                ...model,
                email: e.target.value,
                showPasswordField: false
              });
              setAppContext({ ...appContext, email: e.target.value });
              setEmailIsAvailable(false);
              if (showForgotPassword) {
                setShowForgotPassword(false);
              }
              if (showForgotPasswordButton) {
                setShowForgetPassswordButton(false);
              }
              setSubmitButtonTextId('landing-page.form.button.login-signup');
            }}
          />
          {model.showPasswordField && (
            <Input
              titleId="form.password.title"
              placeholderId="form.password.placeholder"
              value={model.password}
              type="password"
              autoComplete="current-password"
              errorHandler={getErrorHandler(
                nameOf<LandingPageModel>('password')
              )}
              onChange={(e): void =>
                setModel({ ...model, password: e.target.value })
              }
            />
          )}
          {emailIsAvailable && (
            <div className="email-available">
              <FormattedMessage
                id="form.email.available"
                values={{ email: model.email }}
              />
            </div>
          )}
          <Button
            type="submit"
            textId={submitButtonTextId}
            disabled={submitDisabled}
          />
          {showForgotPasswordButton && (
            <>
              <div className="forgot-password">
                <button
                  type="button"
                  onClick={() => setShowForgotPassword(true)}>
                  <FormattedMessage id="landing-page.form.forgot-password" />
                </button>
              </div>
              {showForgotPassword && (
                <ForgotPasswordModal
                  email={model.email}
                  closeModal={() => setShowForgotPassword(false)}
                />
              )}
            </>
          )}
        </form>
      </PageContent>
    </Layout>
  );
}
