import { useContext } from 'react';
import { AppContext } from 'services/appContext/AppContext';
import EvaEndpointProvider from './EvaEndpointProvider';
import {
  RitualsUpdateLoyalty
} from '../../typings/EVA.Rituals.Services';

function useRitualsService() {
  const { appContext } = useContext(AppContext);
  const updateLoyalty = async (
    loyaltyEnabled: boolean
  ) => {
    const evaEndpoint = await EvaEndpointProvider.getEndpoint();

    return evaEndpoint.callService(
      RitualsUpdateLoyalty,
      {
        Enabled: loyaltyEnabled
      },
      {
        authenticationToken: appContext.userToken || appContext.authenticationToken
      }
    );
  };

  return { updateLoyalty  };
};

export default useRitualsService;
