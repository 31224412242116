import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useRitualsService from 'services/eva/RitualsService';
import useUserService from 'services/eva/UserService';
import walletLogo from 'assets/images/btn_add_to_apple_wallet.svg';
import walletLogoFr from 'assets/images/btn_add_to_apple_wallet_fr.svg';
import { AppContext } from 'services/appContext/AppContext';

export default function AppleWallet(): JSX.Element {
  const intl = useIntl();
  const [walletUrl, setWalletUrl] = useState<string>();
  const ritualsService = useRitualsService();
  const userService = useUserService();
  const { appContext } = useContext(AppContext);
  const loyalityName = "RGC_LoyaltyID";

  useEffect(() => {
    Promise.all([
      userService.getUserInfo()
    ]).then(([user]) => {
      const loyality = user?.CustomFields.find(fields => fields.Name === loyalityName);
      if (user?.User.EmailAddress && typeof loyality !== "undefined") {
        setWalletUrl(
          `https://wallet.rcpl.nl/download/rituals/${appContext.locale.replace(
            '-',
            '_'
          )}/${loyality.Value}/${user?.User.EmailAddress}`
        );
      }
    });
  }, [appContext.locale, ritualsService, userService]);

  const getWalletLogo = useCallback(() => {
    if (appContext.locale.includes('fr')) {
      return walletLogoFr;
    }

    return walletLogo;
  }, [appContext.locale]);

  return (
    <div className="apple-wallet">
      {walletUrl && (
        <a href={walletUrl}>
          <img src={getWalletLogo()} alt={intl.formatMessage({ id: 'brand' })} style={{width: "218px"}} />
        </a>
      )}
    </div>
  );
}
