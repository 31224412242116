import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { AppContext } from 'services/appContext/AppContext';
import translations from 'translations';

export interface ICountryRouterProps {
  RouterComponent: React.ComponentClass<any>;
}

export const CountryRouter: React.FC<ICountryRouterProps> = ({
  RouterComponent,
  children
}: React.PropsWithChildren<ICountryRouterProps>) => {
  const { appContext } = useContext(AppContext);
  const [messages, setMessages] = useState({})


  useEffect(() => {
    const defaultLang = translations[appContext.language];
    const messagesToLoad = translations[`${appContext.language}_${appContext.country.toUpperCase()}`]
    if ( messagesToLoad ) {
      setMessages(messagesToLoad)
    } else {
      setMessages(defaultLang);
    }
  }, [appContext])

  return (
    <RouterComponent>
      <Route path="/:country([a-z]{2})">
        {({ match, location }) => {
          /**
           * If countrty is not in route path, add default to path and redirect
           */
          const { pathname } = location;
          if (!pathname.includes(`/${appContext.country}/`)) {
            return (
              <Redirect
                to={`/${appContext.country}${location.pathname}${location.search}`}
              />
            );
          }

          return (
            <IntlProvider
              locale={appContext.language}
              messages={messages}>
              {children}
            </IntlProvider>
          );
        }}
      </Route>
    </RouterComponent>
  );
};

export default CountryRouter;
