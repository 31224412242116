import React, { useState, useContext, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from 'components/shared/Layout';
import Input from 'components/shared/form/Input';
import Button from 'components/shared/form/Button';
import { nameOf } from 'services/ObjectUtil';
import { useErrors } from 'services/errorHandling/useErrors';
import { AddError, NoErrors } from 'services/errorHandling/ErrorUtil';
import { AppContext } from 'services/appContext/AppContext';
import useUserService from 'services/eva/UserService';
import useRitualsService from 'services/eva/RitualsService';
import { useHistory } from 'react-router-dom';
import Checkbox from 'components/shared/form/Checkbox';
import PageHeading from 'components/shared/PageHeading';
import Gender from 'components/shared/form/Gender';
import PageContent from 'components/shared/PageContent';
import Header from 'components/shared/header/Header';
import HeaderCarousel from 'components/shared/header/HeaderCarousel';
import PhoneNumberInput from 'components/shared/form/PhoneNumberInput';
import { privacyLinks, termsLinks } from 'translations';
import { ErrorsModel } from 'services/errorHandling/ErrorModel';
import validateModel from './UpgradeModelValidator';
import {validateDob}  from '../register/RegisterModelValidator';
import { DefaultUpgradeModel, UpgradeModel } from './UpgradeModel';

export default function Upgrade(): JSX.Element {
  const history = useHistory();
  const { getErrorHandler, setErrors } = useErrors();
  const [model, setModel] = useState(DefaultUpgradeModel);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { appContext } = useContext(AppContext);
  const ritualsService = useRitualsService();
  const userService = useUserService();
  const [localUserInfo, setLocalUserInfo] = useState<EVA.Core.GetCurrentUserResponse | undefined>();

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationResult = validateModel(model, appContext.userRequirements, appContext.country);

    setErrors(validationResult);

    if (NoErrors(validationResult) && appContext.userToken) {
      setSubmitDisabled(true);

      const updatedUserID = await userService.updateUser(model);

      if (updatedUserID) {
        await ritualsService.updateLoyalty(true);
        await userService.subscribeToAll(updatedUserID, model.smsOffers);
      }

      history.push('/member');
    }
  };

  const setCurrentUser = useCallback(async () => {
    const userInfo =  await userService.getUserInfo();

    setLocalUserInfo(userInfo);
  }, [userService]);

  useEffect(() => {
    if(localUserInfo) {
      let dob = '';

      if(localUserInfo?.User.DateOfBirth) {
        const date = new Date(localUserInfo?.User.DateOfBirth)
        if(appContext.country !== 'us') {
          dob = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        } else {
          dob = `${date.getMonth() + 1}/${date.getDate()}`;
        }
      }

      setModel({
        emailOffers: false,
        smsOffers: false,
        phoneNumber: localUserInfo.User.PhoneNumber,
        dateOfBirth: dob,
        gender: localUserInfo?.User.Gender
      });
    }
  }, [appContext.country, localUserInfo]);

  useEffect(() => {
    if(!localUserInfo) {
      setCurrentUser();
    }
  }, [setCurrentUser, localUserInfo]);


  function checkValue(str: string, max: number) {
    let newStr = str;
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str, 10);
      if (Number.isNaN(Number(num)) || num <= 0 || num > max) num = 1;
      newStr =
        num > parseInt(max.toString().charAt(0), 10) &&
        num.toString().length === 1
          ? `0${num}`
          : num.toString();
    }
    return newStr;
  }

  function handleValidateDob(dob: string) {
    const isValidDate = validateDob(dob);

    if (!isValidDate) {
      const errors: ErrorsModel = {};

      AddError(
        errors,
        nameOf<UpgradeModel>('dateOfBirth'),
        'form.date-of-birth.required',
        true
      );
      setErrors(errors);
      setSubmitDisabled(true);
    } else {
      setErrors({});
      setSubmitDisabled(false);
    }
  }

  function handleChange(e: any) {
    if (appContext.country === 'us') {
      let input = e.target.value;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
      const values = input.split('/').map((v: any) => {
        return v.replace(/\D/g, '');
      });
      if (values[0]) values[0] = checkValue(values[0], 12);
      if (values[1]) values[1] = checkValue(values[1], 31);
      const output = values.map((v: any, i: any) => {
        return v.length === 2 && i < 2 ? `${v}/` : v;
      });

      setModel({
        ...model,
        dateOfBirth: output.join('').substr(0, 5)
      });
    } else {
      if (appContext.country !== 'us') {
        handleValidateDob(e.target.value);
      }
      setModel({
        ...model,
        dateOfBirth: e.target.value
      });
    }
  }

  return (
    <Layout>
      <Header backButtonLink="/">
        <HeaderCarousel />
      </Header>
      <PageContent className="upgrade">
        <PageHeading>
          <FormattedMessage id="upgrade.heading" />
        </PageHeading>
        <form onSubmit={submitHandler}>
          <Input
            titleId="form.date-of-birth.title"
            type={appContext.country === 'us' ? 'text' : 'date'}
            value={model.dateOfBirth}
            errorHandler={getErrorHandler(nameOf<UpgradeModel>('dateOfBirth'))}
            placeholderId={
              appContext.country === 'us'
                ? 'form.date-of-birth.placeholder.us'
                : 'form.date-of-birth.placeholder'
            }
            onBlur={handleChange}
          />
          <Gender
            value={model.gender}
            errorHandler={getErrorHandler(nameOf<UpgradeModel>('gender'))}
            optional
            onChange={(value): void =>
              setModel({
                ...model,
                gender: value
              })
            }
          />
          <Checkbox
            textId="form.email-offers"
            value={model.emailOffers}
            errorHandler={getErrorHandler(nameOf<UpgradeModel>('emailOffers'))}
            onChange={(value): void =>
              setModel({
                ...model,
                emailOffers: value
              })
            }
          />
          {appContext.country !== 'de' && appContext.country !== 'at' && (
            <>
              <Checkbox
                textId="form.sms-offers"
                value={model.smsOffers}
                errorHandler={getErrorHandler(
                  nameOf<UpgradeModel>('smsOffers')
                )}
                onChange={(value): void =>
                  setModel({
                    ...model,
                    smsOffers: value
                  })
                }
              />
              {model.smsOffers && (
                <PhoneNumberInput
                  titleId="form.phone-number.title"
                  placeholderId="form.phone-number.placeholder"
                  value={model.phoneNumber}
                  errorHandler={getErrorHandler(
                    nameOf<UpgradeModel>('phoneNumber')
                  )}
                  onChange={(e): void =>
                    setModel({
                      ...model,
                      phoneNumber: e.target.value
                    })
                  }
                />
              )}
            </>
          )}
          <div className="terms-notice">
            <FormattedMessage
              id="form.terms-notice"
              values={{
                link: (
                  <a href={termsLinks[appContext.locale] ?? termsLinks.default}>
                    <FormattedMessage id="form.terms-link" />
                  </a>
                ),
                privacy: (
                  <a
                    href={
                      privacyLinks[appContext.locale] ?? privacyLinks.default
                    }>
                    <FormattedMessage id="form.terms-privacy" />
                  </a>
                )
              }}
            />
          </div>
          <Button
            type="submit"
            textId="upgrade.form.button"
            disabled={submitDisabled}
          />
        </form>
      </PageContent>
    </Layout>
  );
}
