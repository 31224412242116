import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import Register from 'components/register/Register';
import Member from 'components/member/Member';
import Upgrade from 'components/upgrade/Upgrade';
import LandingPage from 'components/landingPage/LandingPage';
import { AppContext } from 'services/appContext/AppContext';
import AppContextUtil from 'services/appContext/AppContextUtil';
import ApplicationRoutes from 'Routes';
import ResetPassword from 'components/resetPassword/ResetPassword';
import { first, last } from 'lodash';
import PrivateRoute from 'components/shared/PrivateRoute';
import CountryRouter from 'components/shared/CountryRouter';
import CountrySwitch from 'components/shared/CountrySwitch';
import useCountryService from './services/eva/CountryService';

function App(): JSX.Element {
  // Get the country code from the entry url. expected in the first url segement
  const [, countryOnEntry] = window.location.pathname.split('/');
  const { search } = window.location;
  const params = new URLSearchParams(search);

  let countryParam: string | undefined;
  let languageParam: string | undefined;

  if(params.get('country')?.includes('_')) {
    const splitCountryParam = params.get('country')?.split('_');
    countryParam = last(splitCountryParam)?.toLowerCase();
    languageParam = first(splitCountryParam)?.toLowerCase();
  }

  if(params.get('country')?.includes('-')) {
    const splitCountryParam = params.get('country')?.split('-');
    countryParam = last(splitCountryParam)?.toLowerCase();
    languageParam = first(splitCountryParam)?.toLowerCase();
  }

  // get the confguration from localStorage
  const appConfig = AppContextUtil.getFromLocalStorage();

  const countryService = useCountryService();

  // get countryInfo for requested country
  const countryInfo = countryService.getCountryById(
    countryParam ?? countryOnEntry
  );

  if (countryInfo?.endpoint) {
    appConfig.endpointKey = countryInfo.endpoint;
  } else {
    appConfig.endpointKey = undefined;
  }

  if (countryInfo) {
    // if the requested country is different than the stored config, reset the usertoken and language
    if (appConfig.country !== countryInfo.id) {
      appConfig.userToken = undefined;
      // [ appConfig.language ] = countryInfo.availableLanguages;
    }

    appConfig.country = countryInfo.id;
    appConfig.availableLanguages = [];
    appConfig.authenticationToken = countryInfo.token;
    const langaugeOnEntry = params.get('lang');

    if (languageParam && languageParam.length === 2) {
      appConfig.language = languageParam;
    } else if (langaugeOnEntry && langaugeOnEntry.length === 2) {
      appConfig.language = langaugeOnEntry;
    }
  }

  appConfig.locale = `${appConfig.language.toLowerCase()}-${appConfig.country.toUpperCase()}`;

  const [appContext, setAppContext] = useState(appConfig);

  const appContextWrapper = useMemo(() => ({ appContext, setAppContext }), [
    appContext,
    setAppContext
  ]);

  const getCountries = useCallback(
    (token: string) => {
      return countryService
        .getApplicationConfigurationForCountry(token)
        .then(result => {
          const cultures: {
            LanguageID: string;
            CountryID: string;
            Culture: string;
          }[] = result?.Configuration['Cultures'];
          const userRequirements: EVA.Core.UserRequirementFor[] =
            result?.Configuration['UserRequirements'];
          const defaultLanguage = result?.Configuration['OrganizationUnitCulture'].LanguageID;
        
          const availableLanguages = cultures.map(culture => {
            return culture.LanguageID;
          });

          const newContext: AppContext = {
            ...appContext,
            availableLanguages,
            userRequirements,
            currencyID: result?.Configuration['CurrencyID']
          };

          if (defaultLanguage !== null) {
            newContext.language = defaultLanguage;
          }

          // if the current languge is avialble
          if (!availableLanguages.includes(appContext.language)) {
            const defaultLangForCountry = defaultLanguage;
            if (defaultLangForCountry) {
              newContext.language = defaultLangForCountry;
            }
          }


          return newContext;
        });
    },
    [appContext, countryService]
  );

  useEffect(() => {
    AppContextUtil.saveToLocalStorage(appContext);
  }, [appContext]);

  useEffect(() => {
    async function asyncGetAvailableLanguages(token: string) {
      const appContextWithLanguages = await getCountries(token);
      setAppContext(appContextWithLanguages);
    }

    AppContextUtil.saveToLocalStorage(appContext);

    if (
      appContext.availableLanguages.length === 0 &&
      appContext.authenticationToken
    ) {
      asyncGetAvailableLanguages(appContext.authenticationToken);
    }
  }, [appContext, getCountries]);

  return (
    <AppContext.Provider value={appContextWrapper}>
      <CountryRouter RouterComponent={BrowserRouter}>
        <CountrySwitch>
          <Route path={ApplicationRoutes.register}>
            <Register />
          </Route>
          <Route path={ApplicationRoutes.resetPassword}>
            <ResetPassword />
          </Route>
          <PrivateRoute path={ApplicationRoutes.member}>
            <Member />
          </PrivateRoute>
          <PrivateRoute path={ApplicationRoutes.upgrade}>
            <Upgrade />
          </PrivateRoute>
          <Route path={ApplicationRoutes.home}>
            <LandingPage />
          </Route>
        </CountrySwitch>
      </CountryRouter>
    </AppContext.Provider>
  );
}

export default App;
