import React, { ReactNode, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ErrorHandler } from 'services/errorHandling/useErrors';
import ErrorMessage from './ErrorMessage';

type Props = {
  children: ReactNode;
  titleId: string;
  errorId: string | undefined;
  value?: string | number | string[] | undefined;
  onChange: ((event: React.ChangeEvent<HTMLSelectElement>) => void) | undefined;
  errorHandler: ErrorHandler;
  dark?: boolean;
  selectedIcon?: any;
};

export default function Select(props: Props): JSX.Element {
  const errors = props.errorHandler.getErrors();
  const select: React.RefObject<HTMLSelectElement> = createRef();

  return (
    <div className="form-row">
      <span
        className={classNames(
          'input-label',
          props.dark ? 'select-label-dark' : undefined
        )}>
        <FormattedMessage id={props.titleId} />
      </span>
      <ErrorMessage errors={errors} />
      <div
        className={classNames(
          props.selectedIcon ? 'select-icon-wrapper' : undefined
        )}>
        {props.selectedIcon && (
          <img src={props.selectedIcon} alt={props.titleId} />
        )}
        <select
          className={classNames(
            props.dark ? 'select-dark' : 'select',
            errors ? 'input-error' : undefined
          )}
          value={props.value}
          tabIndex={-1}
          ref={select}
          onChange={props.onChange}>
          {props.children}
        </select>
      </div>
    </div>
  );
}
