import { ErrorsModel, ErrorModel } from './ErrorModel';

export const AddError = (
  errors: ErrorsModel,
  key: string,
  message: string,
  hasTranslation: boolean = true,
  value: string = ''
) => {
  const error: ErrorModel = { message, hasTranslation, value };

  if (key in errors) {
    errors[key].push(error);
  } else {
    // eslint-disable-next-line no-param-reassign
    errors[key] = [error];
  }
};

export const NoErrors = (errors: ErrorsModel): boolean => {
  return Object.keys(errors).length === 0;
};

export const getParameterCaseInsensitive = (
  model: ErrorsModel,
  key: string
) => {
  if (model) {
    const objectKey: string | undefined = Object.keys(model).find(
      k => k.toLowerCase() === key.toLowerCase()
    );

    if (objectKey) {
      return model[objectKey];
    }
  }

  return [];
};
