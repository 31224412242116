import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

type Props = {
  textId: string;
  type: 'submit';
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  disabled?: boolean;
};

export default function Button(props: Props): JSX.Element {
  return (
    <div className="form-row-button">
      <button
        type="submit"
        className={classNames(
          'btn',
          props.disabled ? 'btn-disabled' : undefined
        )}
        onClick={props.onClick}>
        <FormattedMessage id={props.textId} />
      </button>
    </div>
  );
}
