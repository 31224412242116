import React from 'react';
import EvaEndpointProvider from 'services/eva/EvaEndpointProvider';
import ReactDOM from 'react-dom';
import App from './App';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './assets/css/index.scss';

// bootstrap eva before launcing app
EvaEndpointProvider.getEndpoint().then(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
