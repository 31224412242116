import nl from './nl.svg';
import en from './en.svg';
import de from './de.svg';
import es from './es.svg';
import fr from './fr.svg';
import sv from './sv.svg';
import pt from './pt.svg';
import pl from './pl.svg';
import no from './no.svg';
import da from './da.svg';
import it from './it.svg';
import zh from './hk.svg';
import fi from './fi.svg';

export default { fi, nl, en, de, es, fr, sv, pt, pl, no, da, it, zh};