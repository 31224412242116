/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorHandler } from 'services/errorHandling/useErrors';
import ErrorMessage from './ErrorMessage';

type Props = {
  value?: string;
  onChange: (value?: string) => void;
  errorHandler: ErrorHandler;
  optional?: boolean;
};

export default function Gender(props: Props): JSX.Element {
  const errors = props.errorHandler.getErrors();

  const handleClick = (newGender: string) => {
    props.onChange(props.value === newGender ? undefined : newGender);
  };

  return (
    <div className="form-row">
      <div className="gender-container">
        <div className="label">
          <span>
            <FormattedMessage id="form.gender.title" />
          </span>
          {props.optional && (
            <span>
              <FormattedMessage id="optional" />
            </span>
          )}
        </div>
        <ErrorMessage errors={errors} />
        <div
          className={classNames('gender', errors ? 'gender-error' : undefined)}>
          <div
            className={classNames('item', {
              'item-selected': props.value === 'M'
            })}
            onClick={() => handleClick('M')}>
            <FormattedMessage id="form.gender.male" />
          </div>
          <div
            className={classNames('item', {
              'item-selected': props.value === 'F'
            })}
            onClick={() => handleClick('F')}>
            <FormattedMessage id="form.gender.female" />
          </div>
          <div
            className={classNames('item', {
              'item-selected': props.value === 'O'
            })}
            onClick={() => handleClick('O')}>
            <FormattedMessage id="form.gender.other" />
          </div>
        </div>
      </div>
    </div>
  );
}
