import en from './en.json';
import de from './de.json';
import de_CH from './de_CH.json';
import en_IE from './en_IE.json';
import fr from './fr.json';
import nl from './nl.json';
import sv from './sv.json';
import es from './es.json';
import pt from './pt.json';
import pl from './pl.json';
import no from './no.json';
import da from './da.json';
import it from './it.json';
import fi from './fi.json';
import zh_HK from './zh_HK.json';

const translations = {
  da,
  de,
  de_CH,
  en,
  en_IE,
  es,
  fi,
  fr,
  it,
  nl,
  no,
  pl,
  pt,
  sv,
  zh_HK,
} as any;

export const termsLinks: any = {
  default: 'https://www.rituals.com/eng-de/terms-and-conditions.html',
  'de-AT': 'https://www.rituals.com/deu-at/terms-and-conditions.html',
  'de-CH': 'https://www.rituals.com/de-ch/terms-and-conditions.html',
  'de-DE': 'https://www.rituals.com/de-de/terms-and-conditions.html',
  'en-AT': 'https://www.rituals.com/eng-at/terms-and-conditions.html',
  'en-CH': 'https://www.rituals.com/de-ch/terms-and-conditions.html',
  'en-DE': 'https://www.rituals.com/eng-de/terms-and-conditions.html',
  'en-GB': 'https://www.rituals.com/en-gb/terms-and-conditions.html',
  'en-LU': 'https://www.rituals.com/fra-lu/terms-and-conditions.html',
  'en-US': 'https://www.rituals.com/en-us/terms-and-conditions.html',
  'en-IE': 'https://www.rituals.com/eng-ie/terms-and-conditions.html',
  'fr-BE': 'https://www.rituals.com/fra-be/terms-and-conditions.html',
  'fr-CH': 'https://www.rituals.com/fr-ch/terms-and-conditions.html',
  'fr-LU': 'https://www.rituals.com/fra-lu/terms-and-conditions.html',
  'fr-FR': 'https://www.rituals.com/fr-fr/terms-and-conditions.html',
  'nl-BE': 'https://www.rituals.com/nl-be/terms-and-conditions.html',
  'nl-NL': 'https://www.rituals.com/nl-nl/terms-and-conditions.html',
  'sv-SE': 'https://www.rituals.com/sv-se/terms-and-conditions.html',
  'en-SE': 'https://www.rituals.com/sv-se/terms-and-conditions.html',
  'en-ES': 'https://www.rituals.com/eng-es/terms-and-conditions.html',
  'es-ES': 'https://www.rituals.com/es-es/terms-and-conditions.html',
  'en-FI': 'https://www.rituals.com/eng-fi/terms-and-conditions.html',
  'pt-PT': 'https://www.rituals.com/pt-pt/terms-and-conditions.html',
  'en-PT': 'https://www.rituals.com/eng-pt/terms-and-conditions.html',
  'no-NO': 'https://www.rituals.com/no-no/terms-and-conditions.html',
  'en-NO': 'https://www.rituals.com/eng-no/terms-and-conditions.html',
  'pl-PL': 'https://www.rituals.com/pl-pl/terms-and-conditions.html',
  'en-PL': 'https://www.rituals.com/eng-pl/terms-and-conditions.html',
  'en-DK': 'https://www.rituals.com/eng-dk/terms-and-conditions.html',
  'da-DK': 'https://www.rituals.com/da-dk/terms-and-conditions.html',
  'it-IT': 'https://www.rituals.com/it-it/terms-and-conditions.html',
  'en-HK': 'https://www.rituals.com/en-hk/terms-and-conditions.html',
  'zh-HK': 'https://www.rituals.com/zh-hk/terms-and-conditions.html',
  'fi-FI': 'https://www.rituals.com/fi-fi/terms-and-conditions.html',
};

export const privacyLinks: any = {
  default: 'https://www.rituals.com/eng-de/privacy-policy.html',
  'de-AT': 'https://www.rituals.com/deu-at/privacy-policy.html',
  'de-CH': 'https://www.rituals.com/de-ch/privacy-policy.html',
  'de-DE': 'https://www.rituals.com/de-de/privacy-policy.html',
  'en-AT': 'https://www.rituals.com/eng-at/privacy-policy.html',
  'en-CH': 'https://www.rituals.com/de-ch/privacy-policy.html',
  'en-DE': 'https://www.rituals.com/eng-de/privacy-policy.html',
  'en-GB': 'https://www.rituals.com/en-gb/privacy-policy.html',
  'en-LU': 'https://www.rituals.com/fra-lu/privacy-policy.html',
  'en-US': 'https://www.rituals.com/en-us/privacy-policy.html',
  'en-IE': 'https://www.rituals.com/eng-ie/privacy-policy.html',
  'en-FI': 'https://www.rituals.com/eng-fi/privacy-policy.html',
  'fr-BE': 'https://www.rituals.com/fra-be/privacy-policy.html',
  'fr-CH': 'https://www.rituals.com/fr-ch/privacy-policy.html',
  'fr-LU': 'https://www.rituals.com/fra-lu/privacy-policy.html',
  'fr-FR': 'https://www.rituals.com/fr-fr/privacy-policy.html',
  'nl-BE': 'https://www.rituals.com/nl-be/privacy-policy.html',
  'nl-NL': 'https://www.rituals.com/nl-nl/privacy-policy.html',
  'sv-SE': 'https://www.rituals.com/sv-se/privacy-policy.html',
  'en-SE': 'https://www.rituals.com/sv-se/privacy-policy.html',
  'en-ES': 'https://www.rituals.com/eng-es/privacy-policy.html',
  'es-ES': 'https://www.rituals.com/es-es/privacy-policy.html',
  'pt-PT': 'https://www.rituals.com/pt-pt/privacy-policy.html',
  'en-PT': 'https://www.rituals.com/eng-pt/privacy-policy.html',
  'no-NO': 'https://www.rituals.com/no-no/privacy-policy.html',
  'en-NO': 'https://www.rituals.com/eng-no/privacy-policy.html',
  'pl-PL': 'https://www.rituals.com/pl-pl/privacy-policy.html',
  'en-PL': 'https://www.rituals.com/eng-pl/privacy-policy.html',
  'en-DK': 'https://www.rituals.com/eng-dk/privacy-policy.html',
  'da-DK': 'https://www.rituals.com/da-dk/privacy-policy.html',
  'it-IT': 'https://www.rituals.com/it-it/privacy-policy.html',
  'en-HK': 'https://www.rituals.com/en-hk/privacy-policy.html',
  'zh-HK': 'https://www.rituals.com/zh-hk/privacy-policy.html',
  'fi-FI': 'https://www.rituals.com/fi-fi/privacy-policy.html',
};

export default translations;
