export type MemberCardModel = {
  firstName: string;
  lastName: string;
  loyaltyId?: string;
  isPending?: boolean;
};

export const DefaultMemberCardModel: MemberCardModel = {
  firstName: '',
  lastName: ''
};
