import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'services/appContext/AppContext';
import { useErrors } from 'services/errorHandling/useErrors';
import flags from 'assets/images/flag/';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import ritualsLogo from 'assets/images/rituals_logo_1.1@20x.png';
import facebookLogo from 'assets/images/icon_system_rituals_social_facebook.svg';
import instagramLogo from 'assets/images/icon_system_rituals_social_instagram.svg';
import pinterestLogo from 'assets/images/icon_system_rituals_social_pinterest.svg';
import twitterLogo from 'assets/images/icon_system_rituals_social_twitter.svg';
import youtubeLogo from 'assets/images/icon_system_rituals_social_youtube.svg';
import { privacyLinks, termsLinks } from 'translations';
import Select from './form/Select';

export default function Footer(): JSX.Element {
  const { getErrorHandler } = useErrors();
  const { appContext, setAppContext } = useContext(AppContext);
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [iconFlag, setIconFlag] = useState<string>();

  const intl = useIntl();

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setAppContext({
      ...appContext,
      language: event.target.value,
      locale: `${event.target.value}-${appContext.country.toUpperCase()}`
    });
  };

  useEffect(() => {
    if (appContext.language !== selectedLanguage) {
      setSelectedLanguage(appContext.language);
    }
  }, [appContext, selectedLanguage]);

  useEffect(() => {
    if (selectedLanguage) {
      const langId = selectedLanguage;
      setIconFlag(get(flags, langId));
    }
  }, [selectedLanguage, setIconFlag]);

  return (
    <footer>
      <div className="info">
        <div className="language">
          <div className="heading">
            <FormattedMessage id="footer.language.heading" />
          </div>
          <Select
            titleId="footer.language.select"
            errorId=""
            value={selectedLanguage}
            onChange={handleLanguageChange}
            errorHandler={getErrorHandler('')}
            dark
            selectedIcon={iconFlag}>
            {appContext.availableLanguages?.map(value => {
              const langId = value.split('-')[0];

              if(langId === 'ca') {
                return null;
              }

              return (
                <option key={value} value={value}>
                  {intl.formatMessage({ id: `language.${langId}` })}
                </option>
              );
            })}
          </Select>
        </div>
        <div className="contact">
          <div className="heading">
            <FormattedMessage id="footer.contact.heading" />
          </div>
          <div className="info">
            <span>
              <FormattedMessage id="footer.contact.phone" />
            </span>
            <span>
              <FormattedMessage id="footer.contact.phone-tariff" />
            </span>
          </div>
          <div className="info">
            <span>
              <FormattedMessage id="footer.contact.opening-days" />
            </span>
            <span>
              <FormattedMessage id="footer.contact.opening-hours" />
            </span>
          </div>
          <div className="info">
            <a
              href="https://www.facebook.com/RitualsCosmetics/"
              target="_blank"
              rel="noopener noreferrer">
              <img src={facebookLogo} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/ritualscosmetics/"
              rel="noopener noreferrer">
              <img src={instagramLogo} alt="Instagram" />
            </a>
            <a
              href="https://www.pinterest.com/ritualsofficial/"
              rel="noopener noreferrer">
              <img src={pinterestLogo} alt="Pinterest" />
            </a>
            <a href="https://twitter.com/rituals" rel="noopener noreferrer">
              <img src={twitterLogo} alt="Twitter" />
            </a>
            <a
              href="https://www.youtube.com/user/ritualscosmetics"
              rel="noopener noreferrer">
              <img src={youtubeLogo} alt="Youtube" />
            </a>
          </div>
        </div>
        <div className="logo">
          <a href="https://www.rituals.com" rel="noopener noreferrer">
            <img src={ritualsLogo} alt={intl.formatMessage({ id: 'brand' })} />
          </a>
        </div>
      </div>
      <div className="disclaimer">
        <div className="copyright">
          <FormattedMessage id="footer.copyright" />
        </div>
        <div className="links">
          <a
            href={privacyLinks[appContext.locale] ?? privacyLinks.default}
            rel="noopener noreferrer">
            <FormattedMessage id="footer.privacy" />
          </a>
          <a
            href={termsLinks[appContext.locale] ?? termsLinks.default}
            rel="noopener noreferrer">
            <FormattedMessage id="footer.general-terms" />
          </a>
          <a
            href="https://www.rituals.com/en-nl/faqs.html"
            rel="noopener noreferrer">
            <FormattedMessage id="footer.service" />
          </a>
        </div>
      </div>
    </footer>
  );
}
