import {
  bootstrapEndpoint,
  EvaEndpoint,
} from '@springtree/eva-sdk-core-service';
import { get, find } from 'lodash';
import AppContextUtil from 'services/appContext/AppContextUtil';
import { setCoreSetting } from '@springtree/eva-sdk-core-settings';
import pkg from '../../../package.json';

import evaConfig from '../../assets/config/eva-config.json';

setCoreSetting('appName', 'crm-portal');
setCoreSetting('appVersion', pkg.version);

const EvaEndpointProvider = {

  getEndpoint: async (): Promise<EvaEndpoint> => {

  const appConfig = AppContextUtil.getFromLocalStorage();
  const config = get(evaConfig, 'metadata.alternativeEndpoints');
  const alternativeEndpoints = find(config, { key: appConfig.endpointKey });

  const endpoint = typeof alternativeEndpoints === 'undefined' ?  evaConfig.endpoint : alternativeEndpoints.endpoint;
    const evaEndpoint = bootstrapEndpoint({
        uri: endpoint,
    });

    return evaEndpoint;
  },
};

export default EvaEndpointProvider;
