/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ErrorHandler } from 'services/errorHandling/useErrors';
import ShowPasswordIcon from 'assets/images/icon_system_eye_1.1.svg';
import HidePasswordIcon from 'assets/images/icon_system_eye_1.2.svg';
import NumberFormat from 'react-number-format';
import ErrorMessage from './ErrorMessage';

type Props = {
  titleId: string;
  placeholderId?: string;
  value?: string | number;
  type: 'text' | 'email' | 'date' | 'password';
  autoComplete?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorHandler: ErrorHandler;
  optional?: boolean;
};

export default function Input(props: Props): JSX.Element {
  const intl = useIntl();
  const errors = props.errorHandler.getErrors();
  const [reveal, setReveal] = useState(false);

  return (
    <div className="form-row">
      <div className="label">
        <span>
          <FormattedMessage id={props.titleId} />
        </span>
        {props.optional && (
          <span>
            <FormattedMessage id="optional" />
          </span>
        )}
      </div>
      {props.type === 'date' && (
        <div className="date-info">
          <FormattedMessage id="form.date-of-birth.info" />
        </div>
      )}
      <ErrorMessage errors={errors} />
      {props.type === 'date' ? (
        <NumberFormat
          value={props.value}
          placeholder={
            props.placeholderId &&
            intl.formatMessage({
              id: props.placeholderId
            })
          }
          format="##/##/####"
          mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
          className={classNames('input', errors ? 'input-error' : undefined)}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
      ) : (
        <input
          className={classNames(
            'input',
            errors ? 'input-error' : undefined,
            props.type === 'password' ? 'password' : ''
          )}
          type={props.type === 'password' && reveal ? 'text' : props.type}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          autoComplete={props.autoComplete}
          placeholder={
            props.placeholderId &&
            intl.formatMessage({
              id: props.placeholderId
            })
          }
        />
      )}
      {props.type === 'password' && (
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus
        <div
          className="reveal"
          onClick={() => setReveal(!reveal)}
          role="button">
          <img src={reveal ? HidePasswordIcon : ShowPasswordIcon} />
        </div>
      )}
    </div>
  );
}
