import EvaEndpointProvider from './EvaEndpointProvider';

const EvaService = {
  getAssetsUrl: async () => {
    const evaEndpoint = await EvaEndpointProvider.getEndpoint();

    const applicationConfiguration = evaEndpoint.getApplicationConfiguration();

    return applicationConfiguration?.Configuration['Urls:Assets'];
  }
};

export default EvaService;
