import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorModel } from 'services/errorHandling/ErrorModel';

type Props = {
  errors: ErrorModel[];
};

export default function ErrorMessage(props: Props): JSX.Element {
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (ref && ref.current !== null) {
      if (ref.current.getBoundingClientRect().y < 0) {
        ref.current.scrollIntoView();
      }
    }
  });

  return (
    <>
      {props.errors && props.errors.length > 0 && (
        <span className="form-row-error" ref={ref}>
          {props.errors[0].hasTranslation ? (
            <FormattedMessage
              id={props.errors[0].message}
              values={{
                key: props.errors[0].value
              }}
            />
          ) : (
            props.errors[0].message
          )}
        </span>
      )}
    </>
  );
}
