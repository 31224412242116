/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ErrorHandler } from 'services/errorHandling/useErrors';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { AppContext } from 'services/appContext/AppContext';
import ErrorMessage from './ErrorMessage';

type Props = {
  titleId: string;
  placeholderId?: string;
  value?: string | number;
  autoComplete?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorHandler: ErrorHandler;
  optional?: boolean;
};

export default function PhoneNumberInput(props: Props): JSX.Element {
  const intl = useIntl();
  const errors = props.errorHandler.getErrors();
  const { appContext } = useContext(AppContext);

  const isFrance = useMemo(() => appContext.locale.includes("-FR"), [appContext.locale]);
  
  const numberIsAllowed = useCallback((values: NumberFormatValues) => {
    if (!isFrance) {
      return true;
    }

    if (values.value.length === 1 && values.value[0] !== '6' && values.value[0] !== '7') {
      return false;
    }

    return true;
  }, [isFrance]);

  return (
    <div className="form-row">
      <div className="label">
        <span>
          <FormattedMessage id={props.titleId} />
        </span>
        {props.optional && (
          <span>
            <FormattedMessage id="optional" />
          </span>
        )}
      </div>
      <ErrorMessage errors={errors} />
      <NumberFormat
        value={props.value}
        placeholder={!isFrance ? 
          props.placeholderId  &&
          intl.formatMessage({
            id: props.placeholderId
          }) : '+33 '
        }
        format={isFrance ? '+33 #########' : undefined}
        isAllowed={numberIsAllowed}
        className={classNames('input', errors ? 'input-error' : undefined)}
        onChange={props.onChange}
      />
    </div>
  );
}
