import moment from 'moment';
import { ErrorsModel } from 'services/errorHandling/ErrorModel';
import { AddError } from 'services/errorHandling/ErrorUtil';
import { nameOf } from 'services/ObjectUtil';
import { RegisterModel } from './RegisterModel';

export const validateDob = (
  dob: string
) => {
    const dates = dob.split('/');

    if(!dates[0] || !dates[1] || !dates[2]) {
      return false;
    }

    if (dates[0].includes('D') || dates[1].includes('M') || dates[2].includes('Y')) {
      return false;
    }

    let isValidDate = true;

    const day = parseInt(dates[0], 10);
    const month = parseInt(dates[1], 10);
    const year = parseInt(dates[2], 10);

    // check for valid day (1-31 interval)
    if (day && (day < 1 || day > 31)) {
      isValidDate = false;
    }
    // check for valid month (1-12 interval)
    if (month && (month < 1 || month > 12)) {
      isValidDate = false;
    }
    // check to see if year is not above current year
    if (year && year > new Date().getFullYear()) {
      isValidDate = false;
    }
    // invalidate days or months with 00
    if (
      (dob[0] === '0' && dob[1] === '0') ||
      (dob[3] === '0' && dob[4] === '0')
    ) {
      isValidDate = false;
    }
    // allow years starting only with 19 and 20
    if (
      (dob[6] !== 'Y' &&
        dob[6] !== '2' &&
        dob[6] !== '1') ||
      (dob[7] !== 'Y' &&
        dob[7] !== '0' &&
        dob[7] !== '9') ||
      (dob[6] === '2' && dob[7] === '9')
    ) {
      isValidDate = false;
    }

    return isValidDate;

};

export const validateModel = (
  model: RegisterModel,
  userRequirements: any,
  country: string
) => {
  const errors: ErrorsModel = {};
  const validatorDateOfBirth = userRequirements.DateOfBirth.CustomValidators;

  if (!model.email) {
    AddError(errors, nameOf<RegisterModel>('email'), 'form.email.required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(model.email)) {
    AddError(
      errors,
      nameOf<RegisterModel>('email'),
      'form.email.invalid'
    );
  }

  if (!model.firstName) {
    AddError(
      errors,
      nameOf<RegisterModel>('firstName'),
      'form.first-name.required'
    );
  }
  if (!model.lastName) {
    AddError(
      errors,
      nameOf<RegisterModel>('lastName'),
      'form.last-name.required'
    );
  }
  if (userRequirements.DateOfBirth && (userRequirements.DateOfBirth.Required || userRequirements.DateOfBirth.RequiredFor === 1)) {
    if (!model.dateOfBirth) {
      AddError(
        errors,
        nameOf<RegisterModel>('dateOfBirth'),
        'form.date-of-birth.required'
      );
    } else if (
      validatorDateOfBirth &&
      validatorDateOfBirth.AGE &&
      validatorDateOfBirth.AGE.MinimumAge &&
      moment(model.dateOfBirth, 'DD/MM/YYYY').isAfter(
        moment().subtract(
          validatorDateOfBirth.AGE.MinimumAge,
          'years'
        )
      )
    ) {
      AddError(
        errors,
        nameOf<RegisterModel>('dateOfBirth'),
        'form.date-of-birth.requirements',
        true,
        validatorDateOfBirth.AGE.MinimumAge
      );
    }
  }
  if (
    model.smsOffers &&
    (!model.phoneNumber ||
      (country === 'fr' && model.phoneNumber.trim().length !== 13))
  ) {
    AddError(
      errors,
      nameOf<RegisterModel>('phoneNumber'),
      'form.phone-number.required',
      true,
      validatorDateOfBirth.AGE.MinimumAge
    );
  }
  if (!model.emailOffers) {
    AddError(
      errors,
      nameOf<RegisterModel>('emailOffers'),
      'form.email-offers.required'
    );
  }
  if (!model.gender) {
    AddError(errors, nameOf<RegisterModel>('gender'), 'form.gender.required');
  }

  return errors;
};

export default validateModel;
