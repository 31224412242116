import { useContext, useCallback } from 'react';
import { AppContext } from 'services/appContext/AppContext';
import { Core } from '@springtree/eva-services-core';
import { RegisterModel } from 'components/register/RegisterModel';
import { UpgradeModel } from 'components/upgrade/UpgradeModel';
import moment from 'moment';
import EvaEndpointProvider from './EvaEndpointProvider';

import {
  JanrainRequestPasswordResetToken,
  JanrainResetPassword
} from '../../typings/EVA.Rituals.Services';

function useUserService() {
  const { appContext } = useContext(AppContext);

  const getDate = useCallback((date: string) => {
    if (date) {
      return appContext.country === 'us'
        ? moment(date, 'MM/DD').set('year', 1970).format()
        : moment(date, 'DD/MM/YYYY').format();
    }

    return undefined;
  }, [appContext.country]);

  const login = useCallback(
    async (email: string, password: string) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        Core.Login,
        {
          Username: email,
          Password: password,
          UserType: 2,
          CustomAuthenticatorType: 'JANRAIN',
          AsEmployee: false
        },
        {
          authenticationToken: appContext.authenticationToken
        }
      );
    },
    [appContext]
  );

  const checkEmailAddressAvailability = useCallback(
    async (email: string): Promise<boolean> => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      const response = await evaEndpoint.callService(
        Core.CheckEmailAddressAvailability,
        {
          EmailAddress: email
        },
        {
          authenticationToken: appContext.authenticationToken
        }
      );

      return response !== undefined && response.IsAvailable;
    },
    [appContext]
  );

  const getUserInfo = useCallback(async () => {
    const evaEndpoint = await EvaEndpointProvider.getEndpoint();

    return evaEndpoint.callService(Core.GetCurrentUser, undefined, {
      authenticationToken:
        appContext.userToken || appContext.authenticationToken
    });
  }, [appContext]);

  const getUserDataWithToken = useCallback(async (token) => {
    const evaEndpoint = await EvaEndpointProvider.getEndpoint();

    return evaEndpoint.callService(Core.GetCurrentUser, undefined, {
      authenticationToken:
        token
    });
  }, []);

  const updateUser = useCallback(
    async (model: UpgradeModel) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      const userInfo = await getUserInfo();

      if (userInfo) {
        await evaEndpoint.callService(
          Core.UpdateUser,
          {
            ID: userInfo.User.ID,
            // BackendRelationID: userInfo.User.BackendRelationID,
            Initials: userInfo.User.Initials,
            FirstName: userInfo.User.FirstName,
            LastName: userInfo.User.LastName,
            DateOfBirth: getDate(model.dateOfBirth),
            // PlaceOfBirth: userInfo.User.PlaceOfBirth,
            Gender: model.gender,
            LanguageID: appContext.language || userInfo.User.LanguageID,
            CountryID: userInfo.User.CountryID,
            // Nickname: userInfo.User.Nickname,
            // BankAccount: userInfo.User.BankAccount,
            PhoneNumber: model.smsOffers
              ? model.phoneNumber
              : userInfo.User.PhoneNumber,
            // TimeZone: userInfo.User.TimeZone,
            // FiscalID: userInfo.User.FiscalID,
            // SocialSecurityNumber: userInfo.User.SocialSecurityNumber
          },
          {
            authenticationToken:
              appContext.userToken || appContext.authenticationToken
          }
        );

        return userInfo.User.ID;
      }

      return undefined;
    },
    [appContext.authenticationToken, appContext.language, appContext.userToken, getDate, getUserInfo]
  );

  const register = useCallback(
    async (model: RegisterModel) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        Core.CreateCustomer,
        {
          User: {
            EmailAddress: model.email,
            Gender: model.gender,
            Initials: undefined as any,
            FirstName: model.firstName,
            LastName: model.lastName,
            PhoneNumber: model.phoneNumber,
            DateOfBirth: getDate(model.dateOfBirth),
            PlaceOfBirth: undefined as any,
            BankAccount: undefined as any,
            Nickname: undefined as any,
            Password: model.password,
            LanguageID: appContext.language,
            CountryID: appContext.country.toUpperCase(),
            FiscalID: undefined as any,
            SocialSecurityNumber: undefined as any,
            ShippingAddress: undefined as any,
            BillingAddress: undefined as any
          },
          NoAccount: false,
          AutoLogin: true
        },
        {
          authenticationToken: appContext.authenticationToken
        }
      );
    },
    [appContext.authenticationToken, appContext.country, appContext.language, getDate]
  );

  const subscribe = useCallback(
    async (userId: number, subscriptionId: number) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      await evaEndpoint.callService(
        Core.SubscribeUser,
        {
          UserID: userId,
          SubscriptionID: subscriptionId
        },
        {
          authenticationToken:
            appContext.userToken || appContext.authenticationToken
        }
      );
    },
    [appContext.authenticationToken, appContext.userToken]
  );

  const subscribeToAll = useCallback(
    async (userId: number, smsOffers: boolean) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      const response = await evaEndpoint.callService(
        Core.GetAvailableSubscriptions,
        undefined,
        {
          authenticationToken:
            appContext.userToken || appContext.authenticationToken
        }
      );

      if (response) {
        response.Subscriptions.filter(
          x =>
            x.BackendID === 'general-newsletter' ||
            x.BackendID === 'loyalty-newsletter'
        ).map(async subscription => {
          await subscribe(userId, subscription.ID);
        });

        if (smsOffers) {
          response.Subscriptions.filter(x => x.BackendID === 'loyalty-sms').map(
            async subscription => {
              await subscribe(userId, subscription.ID);
            }
          );
        }
      }
    },
    [appContext.authenticationToken, appContext.userToken, subscribe]
  );

  const getSubscriptions = useCallback(
    async (userId: number) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        Core.GetUserSubscriptions,
        { UserID: userId },
        {
          authenticationToken:
            appContext.userToken || appContext.authenticationToken
        }
      );
    },
    [appContext]
  );

  const requestPasswordResetToken = useCallback(
    async (email: string, locale: string) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        JanrainRequestPasswordResetToken,
        { EmailAddress: email, Locale: locale.replace('-', '_') },
        { authenticationToken: appContext.authenticationToken }
      );
    },
    [appContext]
  );

  const resetPassword = useCallback(
    async (token: string, newPassword: string) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        JanrainResetPassword,
        { NewPassword: newPassword, Token: token },
        { authenticationToken: appContext.authenticationToken }
      );
    },
    [appContext]
  );

  return {
    login,
    checkEmailAddressAvailability,
    getUserInfo,
    getUserDataWithToken,
    updateUser,
    register,
    subscribeToAll,
    getSubscriptions,
    requestPasswordResetToken,
    resetPassword
  };
}

export default useUserService;
