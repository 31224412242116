import { ErrorsModel } from 'services/errorHandling/ErrorModel';
import { AddError } from 'services/errorHandling/ErrorUtil';
import { nameOf } from 'services/ObjectUtil';

const MIN_PASSWORD_LENGTH = 8;

export type ResetPasswordModel = {
  newPassword: string;
  newPasswordRepeat: string
}


const validatePassword = (password: string) => {
  const regex = RegExp(`^(?=.*?[A-Z])(?=.*?[0-9]).{${MIN_PASSWORD_LENGTH},}$`);

  return regex.test(password);
};

const validateModel = (model: ResetPasswordModel) => {
  const errors: ErrorsModel = {};

  if (!model.newPassword) {
    AddError(errors, nameOf<ResetPasswordModel>('newPassword'), 'form.password.required');
  } else if(!validatePassword(model.newPassword)) {
    AddError(errors, nameOf<ResetPasswordModel>('newPassword'), 'form.password.requirements');
  } else if(model.newPassword !== model.newPasswordRepeat) {
    AddError(errors, nameOf<ResetPasswordModel>('newPasswordRepeat'), 'form.confirm-password.match');
  }


  return errors;
};

export default validateModel;
