import React, { useContext } from 'react';
import { Switch, RouteProps } from 'react-router-dom';
import { AppContext } from 'services/appContext/AppContext';

export const CountrySwitch: React.FC = (
  props: React.PropsWithChildren<any>
) => {
  const { appContext } = useContext(AppContext);

  const currentCountry = appContext.country;

  function addCountryToPath(path?: string | string[]) {
    switch (typeof path) {
      case 'undefined':
        return undefined;
      case 'object':
        return path.map(key => `/${currentCountry}${key}`);
      default:
        return path === '*' ? path : `/${currentCountry}${path}`;
    }
  }

  /**
   * Add a country to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <Switch>
      {React.Children.map(props.children, child => {
        return React.isValidElement<RouteProps>(child)
          ? React.cloneElement(child, {
              ...child.props,
              path: addCountryToPath(child.props.path)
            })
          : child;
      })}
    </Switch>
  );
};

export default CountrySwitch;
