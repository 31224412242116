import { ErrorsModel } from 'services/errorHandling/ErrorModel';
import { AddError } from 'services/errorHandling/ErrorUtil';
import { nameOf } from 'services/ObjectUtil';
import moment from 'moment';
import { UpgradeModel } from './UpgradeModel';

const validateModel = (
  model: UpgradeModel,
  userRequirements: any,
  country: string
) => {
  const errors: ErrorsModel = {};
  const validatorDateOfBirth = userRequirements.DateOfBirth.CustomValidators;


  if (!model.dateOfBirth) {
    AddError(
      errors,
      nameOf<UpgradeModel>('dateOfBirth'),
      'form.date-of-birth.required'
    );
  } else if (
    validatorDateOfBirth &&
    validatorDateOfBirth.AGE &&
    validatorDateOfBirth.AGE.MinimumAge &&
    moment(model.dateOfBirth).isAfter(
      moment().subtract(
        validatorDateOfBirth.AGE.MinimumAge,
        'years'
      )
    )
  ) {
    AddError(
      errors,
      nameOf<UpgradeModel>('dateOfBirth'),
      'form.date-of-birth.requirements',
      true,
      validatorDateOfBirth.AGE.MinimumAge
    );
  }
  if (
    model.smsOffers &&
    (!model.phoneNumber ||
      (country === 'fr' && model.phoneNumber.trim().length !== 13))
  ) {
    AddError(
      errors,
      nameOf<UpgradeModel>('phoneNumber'),
      'form.phone-number.required'
    );
  }
  if (!model.emailOffers) {
    AddError(
      errors,
      nameOf<UpgradeModel>('emailOffers'),
      'form.email-offers.required'
    );
  }

  return errors;
};

export default validateModel;
