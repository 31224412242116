import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from 'components/shared/Layout';
import Input from 'components/shared/form/Input';
import Checkbox from 'components/shared/form/Checkbox';
import Button from 'components/shared/form/Button';
import { useHistory } from 'react-router-dom';
import { nameOf } from 'services/ObjectUtil';
import { useErrors } from 'services/errorHandling/useErrors';
import { AppContext } from 'services/appContext/AppContext';
import { NoErrors, AddError } from 'services/errorHandling/ErrorUtil';
import { ErrorsModel } from 'services/errorHandling/ErrorModel';
import useUserService from 'services/eva/UserService';
import useRitualsService from 'services/eva/RitualsService';
import PageContent from 'components/shared/PageContent';
import Header from 'components/shared/header/Header';
import HeaderCarousel from 'components/shared/header/HeaderCarousel';
import PageHeading from 'components/shared/PageHeading';
import Gender from 'components/shared/form/Gender';
import { privacyLinks, termsLinks } from 'translations';
import PhoneNumberInput from 'components/shared/form/PhoneNumberInput';
import { DefaultRegisterModel, RegisterModel } from './RegisterModel';
import {validateDob, validateModel}  from './RegisterModelValidator';
import validateResponse from './CreateCustomerResponseValidator';

export default function Register(): JSX.Element {
  const { appContext, setAppContext } = useContext(AppContext);
  const history = useHistory();
  const { getErrorHandler, setErrors } = useErrors();
  const [model, setModel] = useState({
    ...DefaultRegisterModel,
    email: appContext.email ? appContext.email : ''
  });

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [userCreated, setUserCreated] = useState(0);

  const ritualsService = useRitualsService();
  const userService = useUserService();

  useEffect(() => {
    async function updateSubscriptions() {
      if (userCreated) {
        await ritualsService.updateLoyalty(true);
        await userService.subscribeToAll(userCreated, model.smsOffers);
        history.push('/member');
      }
    }
    updateSubscriptions();
  }, [userCreated, history, ritualsService, userService, model.smsOffers]);

  const handleResponse = async (response: EVA.Core.CreateCustomerResponse) => {
    const errors = validateResponse(response);

    if (NoErrors(errors)) {
      setAppContext({
        ...appContext,
        userToken: response.User.AuthenticationToken
      });
      setUserCreated(response.User.ID);
    } else {
      setErrors(errors);
      setSubmitDisabled(false);
    }
  };

  const handleError = (response: any) => {
    const errors: ErrorsModel = {};

    if (response.data.Error) {
      if (response.data.Error.Type === 'PasswordPolicyViolation') {
        AddError(
          errors,
          nameOf<RegisterModel>('password'),
          response.data.Error.Message,
          false
        );
      }
      if (response.data.Error.Type === 'AGE' || response.data.Error.Message.includes("DateOfBirth")) {
        AddError(
          errors,
          nameOf<RegisterModel>('dateOfBirth'),
          response.data.Error.Message,
          false
        );
      } else {
        AddError(
          errors,
          nameOf<RegisterModel>('email'),
          response.data.Error.Message,
          false
        );
      }
    }

    setErrors(errors);
    setSubmitDisabled(false);
  };

  function checkValue(str: string, max: number) {
    let newStr = str;
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str, 10);
      if (Number.isNaN(Number(num)) || num <= 0 || num > max) num = 1;
      newStr =
        num > parseInt(max.toString().charAt(0), 10) &&
        num.toString().length === 1
          ? `0${num}`
          : num.toString();
    }
    return newStr;
  }


  function handleValidateDob(dob: string) {
    const isValidDate = validateDob(dob);

    if (!isValidDate) {
      const errors: ErrorsModel = {};

      AddError(
        errors,
        nameOf<RegisterModel>('dateOfBirth'),
        'form.date-of-birth.required',
        true
      );
      setErrors(errors);
      setSubmitDisabled(true);
    } else {
      setErrors({});
      setSubmitDisabled(false);
    }
  }

  function handleChange(e: any) {
    if (appContext.country === 'us') {
      let input = e.target.value;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
      const values = input.split('/').map((v: any) => {
        return v.replace(/\D/g, '');
      });
      if (values[0]) values[0] = checkValue(values[0], 12);
      if (values[1]) values[1] = checkValue(values[1], 31);
      const output = values.map((v: any, i: any) => {
        return v.length === 2 && i < 2 ? `${v}/` : v;
      });

      setModel({
        ...model,
        dateOfBirth: output.join('').substr(0, 5)
      });
    } else {
      if (appContext.country !== 'us') {
        handleValidateDob(e.target.value);
      }
      setModel({
        ...model,
        dateOfBirth: e.target.value ?? null
      });
    }
  }

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationResult = validateModel(model, appContext.userRequirements, appContext.country);
    setErrors(validationResult);

    if (NoErrors(validationResult)) {
      setSubmitDisabled(true);

      userService
        .register(model)
        .then(x => handleResponse(x as EVA.Core.CreateCustomerResponse))
        .catch(x => handleError(x));
    }
  };

  return (
    <Layout>
      <Header backButtonLink="/">
        <HeaderCarousel />
      </Header>
      <PageContent className="register">
        <PageHeading>
          <FormattedMessage id="register.heading" />
        </PageHeading>
        <form onSubmit={submitHandler}>
          <Input
            titleId="form.email.title"
            placeholderId="form.email.placeholder"
            type="email"
            value={model.email}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('email'))}
            onChange={(e): void =>
              setModel({ ...model, email: e.target.value })
            }
          />
          <Input
            titleId="form.first-name.title"
            placeholderId="form.first-name.placeholder"
            type="text"
            value={model.firstName}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('firstName'))}
            onChange={(e): void =>
              setModel({ ...model, firstName: e.target.value })
            }
            autoComplete="given-name"
          />
          <Input
            titleId="form.last-name.title"
            placeholderId="form.last-name.placeholder"
            type="text"
            value={model.lastName}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('lastName'))}
            onChange={(e): void =>
              setModel({ ...model, lastName: e.target.value })
            }
            autoComplete="family-name"
          />
          <Input
            titleId="form.date-of-birth.title"
            type={appContext.country === 'us' ? 'text' : 'date'}
            value={model.dateOfBirth}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('dateOfBirth'))}
            placeholderId={
              appContext.country === 'us'
                ? 'form.date-of-birth.placeholder.us'
                : 'form.date-of-birth.placeholder'
            }
            onBlur={handleChange}
          />
          <Gender
            value={model.gender}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('gender'))}
            onChange={(value): void =>
              setModel({
                ...model,
                gender: value
              })
            }
          />
          <Checkbox
            textId="form.email-offers"
            value={model.emailOffers}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('emailOffers'))}
            onChange={(value): void =>
              setModel({
                ...model,
                emailOffers: value
              })
            }
          />
          {appContext.country !== 'de' && appContext.country !== 'at' && (
            <>
              <Checkbox
                textId="form.sms-offers"
                value={model.smsOffers}
                errorHandler={getErrorHandler(
                  nameOf<RegisterModel>('smsOffers')
                )}
                onChange={(value): void =>
                  setModel({
                    ...model,
                    smsOffers: value
                  })
                }
              />
              {model.smsOffers && (
                <PhoneNumberInput
                  titleId="form.phone-number.title"
                  placeholderId="form.phone-number.placeholder"
                  value={model.phoneNumber}
                  errorHandler={getErrorHandler(
                    nameOf<RegisterModel>('phoneNumber')
                  )}
                  onChange={(e): void =>
                    setModel({
                      ...model,
                      phoneNumber: e.target.value
                    })
                  }
                />
              )}
            </>
          )}
          <div className="terms-notice">
            <FormattedMessage
              id="form.terms-notice"
              values={{
                link: (
                  <a href={termsLinks[appContext.locale] ?? termsLinks.default}>
                    <FormattedMessage id="form.terms-link" />
                  </a>
                ),
                privacy: (
                  <a
                    href={
                      privacyLinks[appContext.locale] ?? privacyLinks.default
                    }>
                    <FormattedMessage id="form.terms-privacy" />
                  </a>
                )
              }}
            />
          </div>
          <Button
            type="submit"
            textId="register.form.button"
            disabled={submitDisabled}
          />
        </form>
      </PageContent>
    </Layout>
  );
}
